import SlideGrid from "../SlideGrid";
import { animated, useSpring } from 'react-spring';
import { useAnimationFrames } from "../AnimationWrapper";
import { useStore } from "../../store";
import shallow from "zustand/shallow";
import { useLayoutEffect, useState } from "react";
import { useAnimation } from "../../hooks/animation.hooks";
import ScrollTransitionController, { ScrollTransitionChild, springAnimationValueContext } from "../ScrollTransitionController";
import AnimatedButton from "../AnimatedButton";
import LeftAlignedCaseStudy from "../LeftAlignedCaseStudy";
import RightAlignedCaseStudy from "../RightAlignedCaseStudy";
import { commonStyles } from "../../common/variables";
import SideBySideCaseStudy from "../SideBySideCaseSudy";
import ContactText from "../ContactText";
import ContactForm from "../ContactForm";
import { useCurrentScrollDepth } from "../AnimatedSection";

export const HaloSlide = () => {
  const {
    enterInterp,
    entryExitInterp,
  } = useAnimationFrames();

  const {
    setCaseStudyOptions,
    scrollReadMoreMobile
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
    scrollReadMoreMobile: store.scrollReadMoreMobile,
  }), shallow);

  const [hasSetupOptions, setHasSetupOptions] = useState<boolean>(false);
  const{
    getParallaxAnimationStyles,
    defaultScrollTransition,
  } = useAnimation();

  const {
    isMobile
  } = useCurrentScrollDepth();


  // This is a hack to combine contexts between the old scroller and the new one
  useLayoutEffect(() => {
    if(!hasSetupOptions) {
      setHasSetupOptions(true);
      setCaseStudyOptions({
        currentPageIndex: undefined,  
        totalCaseStudies: 11,
        getMinPage: () => 2,
      })
    }
  }, [
    hasSetupOptions,
    setCaseStudyOptions,
  ])

  const headerStyles = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['100vh', '0vh', '-100vh'],
    })
  });

  const bubblePopIn = useSpring({
    opacity: enterInterp.to({
      range: [0, 1],
      output: [0, 1],
    }),
  })

  const opacityStyle = useSpring({
    opacity: entryExitInterp.to({
      range: [0, .3, 1.6, 2.],
      output: [0.5, 1., 1., 0.3],
    }),
  });

  const scaleStyle = useSpring({
    scale: entryExitInterp.to({
      range: [0, 1, 2],
      output: [0.65, 1, 0.5],
    }),
  });

  const digilineStyle = useSpring({
    y: enterInterp.to({
      range: [0, 1],
      output: ['50vh', '0vh'],
    }),
  });

  const ticketsocketStyle = useSpring({
    x: enterInterp.to({
      range: [0, 1],
      output: ['30vw', '0vw'],
    }),
  });

  const seatingStyle = useSpring({
    x: enterInterp.to({
      range: [0, 1],
      output: ['-30vw', '0vw'],
    }),
  });

  const scraptStyle = useSpring({
    y: enterInterp.to({
      range: [0, 1],
      output: ['-50vh', '0vh'],
    }),
  });

  const formStyles = useSpring({
    opacity: enterInterp.to({
      range: [0, .5, 1],
      output: [0, 0, 1],
    }),
  });

  return (
    <ScrollTransitionController
      style={{
        height: '100%',
        zIndex: 5,
      }}
    >
       <ScrollTransitionChild>
       <>
          <animated.img 
            src='/slide-assets/halo/ellipse-78.svg'
            alt='bubble'
            className='animated-desktop-element'
            style={{
              left: '52px',
              bottom: '154px',
              position: 'fixed',
              ...bubblePopIn,
            }}
            />

          <animated.img 
            src='/slide-assets/halo/ellipse-76.svg'
            alt='bubble'
            className='animated-desktop-element'
            style={{
              top: '56px',
              left: '440px',
              position: 'fixed',
              ...bubblePopIn,
            }}
            />

          <animated.img 
            src='/slide-assets/halo/ellipse-77.svg'
            alt='bubble'
            className='animated-desktop-element'
            style={{
              bottom: '52px',
              right: '134px',
              position: 'fixed',
              ...bubblePopIn,
            }}
            />
          
          <SlideGrid
            headerText="Revolutionizing processes through research and digital innovation"
            headerStyle={headerStyles}
            breadcrumb={['Product Design', 'Innovation']}
            logoUrl='/slide-assets/logos/halo-logo.svg'
            variant='halo'
            rightSide={
              <>
                <div>
                  <animated.img 
                    className='img-1'
                    alt='digiline'
                    style={{
                      ...opacityStyle,
                      ...scaleStyle,
                      ...digilineStyle,
                    }}
                  />
                </div>

                <div>
                  <animated.img 
                    className='img-2'
                    alt='ticketsocket'
                    style={{
                      ...opacityStyle,
                      ...scaleStyle,
                      ...ticketsocketStyle,
                    }}
                  />
                </div>

                <div>
                  <animated.img 
                    className='img-3'
                    alt='digiline'
                    style={{
                      ...opacityStyle,
                      ...scaleStyle,
                      ...seatingStyle,
                    }}
                  />
                </div>

                <div>
                  <animated.img 
                    className='img-4'
                    alt='digiline'
                    style={{
                      ...opacityStyle,
                      ...scaleStyle,
                      ...scraptStyle,
                    }}
                  />
                </div>
                <div>
                  <animated.img
                    className='img-mobile'
                    alt="Halo Group"
                    style={headerStyles}
                  />
                </div>
              </>
            }
            leftSide={
              <AnimatedButton
                onClick={() => setCaseStudyOptions({ scrollingDisabled: false, controlledPageIndex: 2, isOpenCaseStudy: true})}
                text="View Case Studies"
              />
            }
          />
        </>
       </ScrollTransitionChild>
       <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <LeftAlignedCaseStudy 
           topContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) => !isMobile ?
                      <>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs1/halo_cel_image.png" 
                            alt="content-img-1"
                            style={{
                              width: '35vw',
                              left: '0',
                              height: '100vh',
                              position: 'absolute',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              zIndex: -1,
                            }}
                          />
                          <animated.div className="case-study-grid__container--halo-cs1--polygon1"></animated.div>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon2"></animated.div>
                      </>
                      :
                      <>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs1/halo_cel_image.png" 
                            alt="content-img-1"
                            style={{
                              width: '100vw',
                              height: '38vh',
                              left: '0',
                              position: 'absolute',
                              objectFit: 'cover',
                              zIndex: -1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs1/halo_big.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '12vh',
                              right: '-23px',
                              width: '99vw',
                              zIndex: 0,
                              opacity: '50%',
                            }}
                          />
                      </>
                }
              </springAnimationValueContext.Consumer>
            }
            className="case-study-grid__container--halo-cs1"
            header={
             <>
              <div className='case-study-grid__container--halo-cs1__mobile'>
                Halo Products
              </div>
             </> 
            }
            breadcrumbs={[
              <>Product Design</>,
              <>Innovation</>,
            ]}
            theme='halo'
            customHeaderColor="white"
            customCopyColor="white"
            copy={<>On a good day, we build something to solve a challenge presented by one business. On a great day, we replicate and scale up what we’ve built to solve a challenge of business itself.</>}
            onClosedClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false})}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs1/halo_big.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '12vh',
                              left: '-28vw',
                              width: '35vw',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs2/halo-image-1-crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '10vw',
                              width: '35vw',
                              top: '30vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '0',
                              zIndex: 1,
                              height: '12vh',
                              objectFit: 'cover',
                              objectPosition: 'bottom',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-77.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              zIndex: 1,
                              height: '12vh',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              left: '50vw'
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs2/halo-image-1-crop.png" 
                              alt=""
                              className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_vertical'
                              style={{
                                position: commonStyles.position.absolute,
                                width: '80vw',
                                top: '12vh',
                                zIndex: 1,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                opacity: scrollReadMoreMobile ? '0' : '1'
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/ellipse-76.svg" 
                              alt=""
                              className='case-study-grid__container--halo-rotate'
                              style={{
                                position: commonStyles.position.absolute,
                                top: '30vh',
                                right: '-15vw',
                                transform: 'rotate(90deg)',
                                opacity: '50%'
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/clickpic.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                bottom: '18vh',
                                width: '85vw',
                                zIndex: 1,
                              }}
                            />
                        </div>
                      </>  
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm--halo">Prove your humanity<br/><span className="case-study-grid__header-sm">with Clickpic.&nbsp;</span></span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                  
                    When atmos the legendary streetwear boutique came to us to build a platform for their intensely competitive sneaker drops, we immediately understood that in designing their digital queuing solution for leveraging scarce products, we would also have to enter the bot mitigation wars with confidence.
                    <br/>
                    <br/>
                    Halo had internal conversations that were not about malevolent machine learning, but instead focused on the overwhelmingly boring or frustrating nature of the user’s experience of securitization. We took on a study in both the fairness and the fun of how best to securitize with a smarter Proof of Humanity tool.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                            <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/clickpic.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                top: '18vh',
                                left: '-20vw',
                                width: '35vw',
                                zIndex: 1,
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/halo_atmos_img.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '13vw',
                                width: '13vw',
                                top: '30vh',
                                zIndex: 1,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/halo_atmos_img2.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '30vw',
                                width: '13vw',
                                top: '10vh',
                                zIndex: 1,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/ellipse-77.svg" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                bottom: '0',
                                zIndex: 1,
                                left: '-2vw',
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/ellipse-78.svg" 
                              alt=""
                              className='case-study-grid__container--halo-rotate-90'
                              style={{
                                position: commonStyles.position.absolute,
                                top: '-3vh',
                                zIndex: 1,
                                left: '50vw'
                              }}
                            />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '20vh',
                              right: '-10vw',
                              width: '50vw',
                              transform: 'rotate(-90deg)'
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-78.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '5vh',
                              left: '-2vw',
                              width: '20vw',
                              transform: 'rotate(0deg)'
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-78.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '5vh',
                              left: '-2vw',
                              width: '30vw',
                              transform: 'rotate(0deg)'
                            }}
                          />
                          <animated.img
                            style={{
                              position: commonStyles.position.absolute,
                              left: '18vw',
                              bottom: '18vh',
                              width: '727px',
                              height: '91px',
                            }}
                            src="/slide-assets/atmos/atmos-shoe-group.svg" 
                            alt="" 
                          />
                           <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs1.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_vertical'
                            style={{ top: '2vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm">Replace captcha with<span className="case-study-grid__header-sm--halo">&nbsp;mobile device arbitrage</span>.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                Clickpic’s proof of concept was built to determine how much cheating and fraud we could solve by forcing single form submission flows off desktop onto mobile. In the design thinking process of proving we could defeat more bots, this build revealed many creative ways to simultaneously leverage mobile tech for more interesting verification actions than “select all the bicycles.” Halo ended up developing several unique challenges to amuse and authenticate users that also provide genuine brand engagement opportunities.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                          <animated.img 
                              src='/slide-assets/atmos/atmos_slide_shoe1.png'
                              alt='shoe1'
                              className='animated-desktop-element'
                              style={{
                                width: '12%',
                                position: commonStyles.position.absolute,
                                zIndex: 2,
                                bottom: '60px',
                                right: '110vw',
                              }}
                            />
                            <animated.img 
                              src='/slide-assets/atmos/atmos_slide_shoe2.png'
                              alt='shoe2'
                              className='animated-desktop-element'
                              style={{
                                width: '12%',
                                position: commonStyles.position.absolute,
                                zIndex: 2,
                                bottom: '60px',
                                right: '95vw',
                              }}
                            />
                            <animated.img 
                              src='/slide-assets/atmos/atmos_slide_shoe3.png'
                              alt='shoe3'
                              className='animated-desktop-element'
                              style={{
                                width: '12%',
                                position: commonStyles.position.absolute,
                                zIndex: 2,
                                bottom: '60px',
                                right: '80vw',
                              }}
                            />
                            <animated.img 
                              src='/slide-assets/atmos/atmos_slide_shoe6.png'
                              alt='shoe4'
                              className='animated-desktop-element'
                              style={{
                                width: '12%',
                                position: commonStyles.position.absolute,
                                zIndex: 2,
                                bottom: '55px',
                                right: '65vw',
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/atmos_mobile_1.jpg" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '27vw',
                                width: '11vw',
                                top: '30vh',
                                zIndex: 2,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/atmos_app_1.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '10vw',
                                width: '35vw',
                                top: '12vh',
                                zIndex: 1,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/atmos_ship_mobile.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '40vw',
                                width: '13vw',
                                top: '16vh',
                                zIndex: 1,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/ellipse-77.svg" 
                              alt=""
                              className='case-study-grid__container--halo-rotate'
                              style={{
                                position: commonStyles.position.absolute,
                                top: 0,
                                zIndex: 1,
                                left: '55vw',
                                width: '12vw',
                              }}
                            />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '25vh',
                              right: '-10vw',
                              width: '50vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '5vh',
                              left: '15vw',
                              width: '25vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '40vh',
                              left: '-10vw',
                              width: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs2.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '6vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm">Queue<span className="case-study-grid__header-sm--halo">&nbsp;high-volume limited-release customers&nbsp;</span>with DigiLine.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                In the land of competitive sneaker drops, atmos is king. They came to us for a better way to manage raffles, but the business case was there to prove we should swing for the rafters and look to build a platform that would leverage all kinds of scarcity beyond raffles.
                <br/>
                <br/>
                We discerned a need for speed anchored by both client and user, so we delivered templates that could be easily customized for the content management system while also adding features that utilize user behaviors for better personalized marketing and forecasting that can activate viral potential.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon3"></animated.div>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon4"></animated.div>
                          <animated.img 
                            src="/slide-assets/halo/ellipse-77.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '0',
                              zIndex: 1,
                              left: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-78.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '-3vh',
                              zIndex: 1,
                              left: '50vw'
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_digiline_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '8',
                              width: '33vw',
                              top: '12vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_digi_release_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '25vw',
                              width: '33vw',
                              top: '35vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                        <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '5vh',
                              right: '-5vw',
                              width: '50vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '40vh',
                              left: '-10vw',
                              width: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs3.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '6vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </> 
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm">Automate the<span className="case-study-grid__header-sm--halo">&nbsp;management of entries&nbsp;</span>against inventory with KYC assurances.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                  The result was our DigiLine product, which atmos uses to launch around 200 limited releases annually. Our extensive and data-driven user testing proved we could handle over 200,000 entries in one hour with no crashes, and it has scaled up to reliably processing over one million entries in a 24-hour window.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon-small"></animated.div>
                          <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                          <animated.img 
                              src="/slide-assets/halo/case-studies/cs3/halo_concert.jpeg" 
                              alt=""
                              className="case-study-grid__container--halo-img--back"
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/halo_scrapt.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '10vw',
                              width: '13vw',
                              top: '16vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/halo_scrapt_event.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '20vw',
                              width: '13vw',
                              top: '30vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/halo_scrapt_front.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '45vw',
                              width: '13vw',
                              top: '20vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs3/halo_concert.jpeg" 
                            alt="concert-mobile"
                            style={{
                              width: '100vw',
                              top: '-15vh',
                              position: 'absolute',
                              left: '0',
                              filter: 'grayscale(1)',
                              opacity: '10%'
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '10vh',
                              right: '-15vw',
                              width: '50vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '40vh',
                              left: '-10vw',
                              width: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs4.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_vertical'
                            style={{ top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm"><span className="case-study-grid__header-sm--halo">Proof of attendance&nbsp;</span>meets bragging rights.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                  Get complete control over the event entry process and resulting data. Halo’s stand-alone app will integrate attendance verification needs and archival tools so that your user experience runs smoothly from day one.
                  <br/>
                  <br/>
                  Our badges capitalize on Proof of Attendance Protocols (POAP) to create viral collectibles and reward loyalists. The app will notify a user’s phone to verify their attendance and allow event entry, then push badges based on the conditions you set.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/music_attended.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '10vw',
                              width: '13vw',
                              top: '16vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/music_artist.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '20vw',
                              width: '13vw',
                              top: '30vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/small_artist_view.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '30vw',
                              width: '15vw',
                              top: '70vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs4/top_music_artist.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '35vw',
                              width: '13vw',
                              top: '12vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-78.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-270'
                            style={{
                              position: commonStyles.position.absolute,
                              left: '-1vw',
                              bottom: 0,
                              width: '8vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-77.svg" 
                            alt=""
                            className='case-study-grid__container--halo-rotate'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              zIndex: 1,
                              width: '12vw',
                              left: '25vw'
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '5vh',
                              left: '-10vw',
                              width: '60vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-80.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '15vh',
                              left: '-10vw',
                              width: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '40vh',
                              right: '-8vw',
                              width: '40vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs5.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_vertical'
                            style={{ top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm">Unlock<span className="case-study-grid__header-sm--halo">&nbsp;special status rewards&nbsp;</span>for event attendance.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                  Users will enjoy the emotional connection of an all-in-one app experience, collecting badges right alongside their ticket stubs and event photos for reminiscing later. Users can ping friends via social app integration to find out who else is at the event, import and share media from the event, and track their own stats such as preferred teams or “year in review” aggregator.
                  <br/>
                  <br/>
                  No matter what kind of events or products you sell, never miss an opportunity for location-specific messaging again. Amuse fans who are waiting in line for hours, predict those lines, even cap or shorten them in real time using our robust data reporting functions combined with powerfully tailored push notifications.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon5"></animated.div>
                          <animated.div className="case-study-grid__container--halo-cs1--polygon6"></animated.div>
                          <animated.img 
                              src="/slide-assets/halo/case-studies/cs5/spartan_bg.png" 
                              alt=""
                              className="case-study-grid__container--halo-img--back-2"
                          />
                          <animated.img 
                              src="/slide-assets/halo/case-studies/cs5/spartan_trifecta.jpeg"
                              style={{
                                position: commonStyles.position.absolute,
                                left: '-5vw',
                                width: '35vw',
                                top: '15vh',
                                zIndex: 3,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 10,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/halo/case-studies/cs5/spartan_scan.jpeg" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                left: '10vw',
                                width: '35vw',
                                top: '45vh',
                                zIndex: 3,
                                filter: commonStyles.shadow.drop,
                                borderRadius: commonStyles.radius.corner,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 20,
                                }, totalFade),
                              }}
                            />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs5/spartan_bg.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              width: '100vw',
                              height: '100vh',
                              top: '0',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              left: '20vw',
                              filter: 'grayscale(1)',
                              opacity: '30%',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs6.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm">Express checkout built to<span className="case-study-grid__header-sm--halo">&nbsp;endure the elements</span>.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                Spartan brought us the challenges of the world’s largest participatory sport, and we met them with solutions that could wrangle the complete range of events from the world’s most popular obstacle course to multi-stage races and team-based endurance missions.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--halo-cs2"
            theme="halo"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.div className="case-study-grid__container--halo-layer"></animated.div>
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs5/spartan_terms.jpeg"
                            style={{
                              position: commonStyles.position.absolute,
                              left: '5vw',
                              width: '35vw',
                              top: '38vh',
                              zIndex: 3,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs5/spartan_scan_success.jpeg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '15vw',
                              width: '35vw',
                              top: '15vh',
                              zIndex: 3,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs5/spartan.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '18vw',
                              width: '50vw',
                              zIndex: 1,
                            }}
                          />   
                          <animated.img 
                            src="/slide-assets/halo/ellipse-77.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '-8vw',
                              bottom: 0,
                              width: '30vw',
                            }}
                          />     
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/halo/ellipse-76.png" 
                            alt=""
                            className='case-study-grid__container--halo-rotate-90'
                            style={{
                              position: commonStyles.position.absolute,
                              top: '10vh',
                              left: '-10vw',
                              width: '30vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/halo/case-studies/cs5/spartan.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '18vh',
                              left: '25vw',
                              zIndex: 1,
                            }}
                          />  
                          <animated.img 
                            src="/slide-assets/halo/mobile_cs/group_cs7.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_horizontal'
                            style={{ zIndex: 1, top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm"><span className="case-study-grid__header-sm--halo">Focus on check-in&nbsp;</span>and not internet connectivity.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy">
                  We created a stand-alone app with express checkout built to endure the elements of inclement weather, limited connectivity, and massive crowds. Similar to the experience of checking in for an airline flight, athletes can access their ticket confirmation via a touchscreen kiosk to perform event day check-in, upgrades and RFID assignments all from a single app.
                  <br/>
                  <br/>
                  Spartan remains on a mission to create unbreakable people and Halo’s creative partnership resulted in a toughened site that minimizes daily contingencies commonly occurring within a high-octane community of over 10,000 event participants at a time.
                </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{zIndex: 2}}
        >
          <SideBySideCaseStudy
            rootContent={
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  backgroundColor: '#ffffff',
                }}
              >
                 <animated.div className="h-100">
                  <SlideGrid
                    headerText="How can we help you?"
                    headerStyle={headerStyles}
                    breadcrumb={[]}
                    leftSide={
                      <ContactText />
                    }
                    variant='contact-slide'
                    rightSide={
                      <animated.div className='contact' style={formStyles}>
                        <ContactForm/>
                      </animated.div>
                    }
                  />
                </animated.div>
              </div>
              }
            />  
        </ScrollTransitionChild> 
    </ScrollTransitionController>  
   
  )
}