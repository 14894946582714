import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

interface props {
  text?: string;
  variant?: 'light' | 'dark';
  animatedOn?: {
    enter?: boolean;
    exit?: boolean;
  }
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const AnimatedButton = (props: props) => {

  const {
    text,
    variant = 'light',
    animatedOn = {
      enter: true,
      exit: true,
    },
    onClick = () => (null),
    className,
    disabled = false,
    type,
  } = props;

  const [hovered, setHovered] = useState(false);

  const hoverEffectSpring = useSpring({
    width: hovered? '150%' : '0%',
    immediate: (hovered && !animatedOn.enter) || (!hovered &&!animatedOn.exit),
  });

  return (
    <animated.button 
      className={`animated-button animated-button--${variant} ${className} ${disabled? 'disabled' : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      onTouchEnd={onClick}
      type={type}
      disabled={disabled}
    >
      <animated.span className='fader' style={hoverEffectSpring}/>
      {text}
    </animated.button>
  );
};

export default AnimatedButton;