import { useCallback, useLayoutEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useStore } from '../../store';
import { useAnimationFrames } from '../AnimationWrapper';
import LeftAlignedCaseStudy from '../LeftAlignedCaseStudy';
import ScrollTransitionController, { ScrollTransitionChild, springAnimationValueContext } from '../ScrollTransitionController';
import SlideGrid from '../SlideGrid';
import shallow from 'zustand/shallow';
import RightAlignedCaseStudy from '../RightAlignedCaseStudy';
import AnimatedButton from '../AnimatedButton';
import { useAnimation } from '../../hooks/animation.hooks';
import SideBySideCaseStudy from '../SideBySideCaseSudy';
import Typeography from '../Typeography';
import { useCurrentScrollDepth } from '../AnimatedSection';


interface props {
}

export const UMGSlide = (props: props) => {

    // temporary solution to scroll to next case study
    const {
      setFrame,
      frame,
      pageBreaks,
    } = useCurrentScrollDepth();
  
    const scrollIn = useCallback((startingFrame: number, endingFrame: number, timeoutMs: number, steps = 5) => {
      if(steps < 1)
      {
        throw Error('Tried animating with an invalid # of steps!');
      }
  
      const timeoutMsStep = timeoutMs / steps;
      const frameStep = (endingFrame - startingFrame) / steps;
      for(let i = 0; i <= steps; i++)
      {
        setTimeout(() => setFrame(startingFrame + i * frameStep), timeoutMsStep * i);
      }
    }, [setFrame]);
  
    const onPageDown = useCallback((frame: number) => {
      let currentBreakpointIndex = 0;
      for(; currentBreakpointIndex < pageBreaks.length; currentBreakpointIndex++)
      {
        if(frame >= pageBreaks[currentBreakpointIndex].enterStart && frame <= pageBreaks[currentBreakpointIndex].exitEnd)
        {
          break;
        }
      }
      currentBreakpointIndex += 1;
      if(currentBreakpointIndex >= pageBreaks.length)
      {
        setFrame(pageBreaks[pageBreaks.length-1].exitEnd);
      }
      else
      {
        scrollIn(frame, pageBreaks[currentBreakpointIndex].enterEnd, 500);
      }
    }, [pageBreaks, scrollIn, setFrame]);
  
    // end of temp solution

  const {
    enterInterp,
    exitInterp,
    entryExitInterp,
  } = useAnimationFrames();


  const {
    setCaseStudyOptions,
    scrollReadMoreMobile
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
    scrollReadMoreMobile: store.scrollReadMoreMobile,
  }), shallow);

  const [hasSetupOptions, setHasSetupOptions] = useState<boolean>(false);
  const{
    getParallaxAnimationStyles,
    defaultScrollTransition,
  } = useAnimation();

  const {
    isMobile
  } = useCurrentScrollDepth();


  // This is a hack to combine contexts between the old scroller and the new one
  useLayoutEffect(() => {
    if(!hasSetupOptions) {
      setHasSetupOptions(true);
      setCaseStudyOptions({
        currentPageIndex: undefined,  
        totalCaseStudies: 8,
        getMinPage: () => 2,
      })
    }
  }, [
    hasSetupOptions,
    setCaseStudyOptions,
  ])

  const gagaSpring = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['-100vh', '0vh', '-100vh'],
    }),
    opacity: enterInterp,
  });

  const snoopSpring = useSpring({
    x: enterInterp.to({
      range: [0, 1],
      output: ['-100vw', '0vw'],
    }),
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['-100vh', '0vh', '-100vh'],
    }),
    opacity: enterInterp,
  });

  const miaSpring = useSpring({
    x: enterInterp.to({
      range: [0, 1],
      output: ['10em', '0'],
    }),
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['-100vh', '0vh', '-100vh'],
    }),
    opacity: enterInterp,
  });

  const mgkSpring = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['70vh', '0', '-100vh'],
    }),
    x: enterInterp.to({
      range: [0, 1],
      output: ['1vw', '0'],
    }),
    opacity: enterInterp,
  });

  const arianaSpring = useSpring({
    x: enterInterp.to({
      range: [0, 1],
      output: ['70vw', '0'],
    }),
    y: exitInterp.to({
      range: [0, 1],
      output: ['0vh', '-100vh'],
    }),
    opacity: enterInterp,
  });

  const headerStyles = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['100vh', '0vh', '-100vh'],
    })
  })

  const backgroundSpring = useSpring({
    opacity: enterInterp.to({
      range: [0.8, 1],
      output: [1, 0],
    }),
  });

  return (
      <ScrollTransitionController
        style={{
          height: '100%',
          zIndex: 5,
        }}
      >
        <ScrollTransitionChild>
          <SlideGrid
            variant='umg'
            headerText="Crafting Epic Websites with Diverse Aesthetics"
            breadcrumb={['Design System', 'Dev', 'Gaming']}
            headerStyle={headerStyles}
            style={{
              color: 'white',
            }}
            backgroundStyle={backgroundSpring}
            logoUrl="/slide-assets/umg/umg-logo.svg"
            rightSide={
              <div>
                <animated.img
                  className='img-1'
                  alt="Lady Gaga"
                  style={{
                    ...gagaSpring,
                  }}
                />

                <animated.img
                  className='img-2'
                  alt="Mia"
                  style={{
                    ...miaSpring,
                  }}
                />

                <animated.img
                  className='img-3'
                  alt="Snoop Dogg"
                  style={{
                    ...snoopSpring,
                  }}
                />

                <animated.img
                  className='img-4'
                  alt="machine gun kelly"
                  style={{
                    ...mgkSpring,
                  }}
                />

                <animated.img
                  className='img-5'
                  alt="ariana grande"
                  style={{
                    ...arianaSpring,
                  }}
                />

                <animated.img
                  className='img-mobile'
                  alt="UMG Group"
                  style={headerStyles}
                />
              </div>
            }
            leftSide={
              <AnimatedButton
                onClick={() => setCaseStudyOptions({ scrollingDisabled: false, controlledPageIndex: 2, isOpenCaseStudy: true})}
                text="View Case Studies"
              />
            }
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <LeftAlignedCaseStudy 
            theme='umg'
            className="case-study-grid__container--umg-cs1"
            header="Universal Music Group"
            breadcrumbs={[
              <>Design System</>,
              <>DEV</>,
              <>GAMING</>,
            ]}
            leftBoosted={<>400</>}
            leftThin={<>Sites a year</>}
            rightBoosted={<>10-12 Days</>}
            rightThin={<>Average delivery time</>}
            copy={<>Halo is proud to be UMG’s digital agency of choice, offering design and product strategy firmly rooted in data to meet the client in multitasking at high volume to generate fan loyalty through boosted engagements. Our methodologies produce influential results and the compliment we get from UMG most often is “Halo can fix this,” because we’ve cultivated both the technology and the relationship. That’s what it takes to build 400 sites per year with an end-to-end average delivery time of just 10-12 days.</>}
            onClosedClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, scrollReadMoreMobile: false, isOpenCaseStudy: false})}
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <RightAlignedCaseStudy
            className={`${!isMobile ? 'case-study-grid__container--umg-cs2' : ''} case-study-grid__container--umg-background`}
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>  
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.img 
                            src="/slide-assets/umg/case-studies/cs1/alessia-music.png" 
                            alt=""
                            style={{
                              position: 'absolute',
                              top: '5vh',
                              left: '20vw',
                              width: '40vw',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/umg/case-studies/cs1/alessia-cara.png" 
                            alt=""
                            style={{
                              position: 'absolute',
                              top: '30vh',
                              left: '-5vw',
                              width: '40vw',
                              ...getParallaxAnimationStyles({
                                farness: 20,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -18,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/umg/case-studies/cs1/alessia-mobile.png" 
                            alt=""
                            style={{
                              position: 'absolute',
                              top: '52vh',
                              left: '40vw',
                              width: '10vw',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/umg/mobile_cs/group_cs1.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ opacity: scrollReadMoreMobile ? '0' : '1' }}
                          />
                          <animated.img 
                            src="/slide-assets/umg/mobile_cs/umg_mobile.png" 
                            alt=""
                            style={{
                              position: 'absolute',
                              bottom: '6vh',
                              right: '1vw',
                              width: '85vw',
                            }}
                          />
                        </div>
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Alessia Cara’s daring and sassy, arthouse-style site continues to draw over<span className="case-study-grid__header-sm--colored">&nbsp;50,000 unique visits&nbsp;</span>per month.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">Nearly a year post-launch this site keeps the engagement alive. For Alessia, we built a non-traditional site full of ultrarich visuals and almost no words. The layout is more of a booklet and introduces a way of feeling like the user is expanding each category of content to view more. </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <RightAlignedCaseStudy
          className={`${!isMobile ? 'case-study-grid__container--umg-cs3' : ''} case-study-grid__container--umg-background`}
            leftContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) =>
                    <>
                      <div className='case-study-grid__container--desktop'>
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs2/bigboi.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '-150vh',
                            left: '7vw',
                            width: '40vw',
                            ...getParallaxAnimationStyles({
                              farness: 40,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs2/phantogram.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '-100vh',
                            left: '17vw',
                            width: '40vw',
                            ...getParallaxAnimationStyles({
                              farness: 20,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs2/super-games.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '40vw',
                            top: '5vh',
                            left: '5vw',
                            ...getParallaxAnimationStyles({
                              farness: 60,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                      </div>
                      <div className='case-study-grid__container--mobile'>
                        <animated.img 
                          src="/slide-assets/umg/mobile_cs/group_cs2.png" 
                          alt=""
                          className='case-study-grid__container--mobile-image'
                          style={{ opacity: scrollReadMoreMobile ? '0' : '1'  }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/mobile_cs/umg_mobile_2.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            top: '6vh',
                            left: '1vw',
                            width: '85vw',
                          }}
                        />
                      </div>
                    </>
                }
              </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>This game operated to stabilize an irregular cadence in the growth cycle at the label, unblocking a new artery to<span className="case-study-grid__header-sm--colored">&nbsp;keep fans’ blood pumping</span>.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">Epic Records reached out to us for an 8-bit interactive game for the group Big Grams. They wanted to gain press, fan engagement & overall a viral experience that would keep people coming back. The Supergrams game accomplished all of those goals for both the artists and the label.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <RightAlignedCaseStudy
            className={`${!isMobile ? 'case-study-grid__container--umg-cs4' : ''} case-study-grid__container--umg-background`}
            leftContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) => 
                    <>
                      <div className='case-study-grid__container--desktop'>
                      <animated.img 
                          src="/slide-assets/umg/case-studies/cs3/artist-roster.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '-50vh',
                            left: '7vw',
                            width: '13vw',
                            ...getParallaxAnimationStyles({
                              farness: 60,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs3/chilombo-out-now.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            top: '20vh',
                            left: '17vw',
                            width: '40vw',
                            ...getParallaxAnimationStyles({
                              farness: 40,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs3/music-releases.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '38vw',
                            bottom: '-70vh',
                            left: '12vw',
                            ...getParallaxAnimationStyles({
                              farness: 20,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                      </div>
                      <div className='case-study-grid__container--mobile'>
                        <animated.img 
                          src="/slide-assets/umg/mobile_cs/group_cs3.png" 
                          alt=""
                          className='case-study-grid__container--mobile-image'
                          style={{ opacity: scrollReadMoreMobile ? '0' : '1'  }}
                        />
                        <animated.img 
                         src="/slide-assets/umg/mobile_cs/defjam_mobile.png" 
                         alt=""
                         style={{
                           position: 'absolute',
                           bottom: '31vh',
                           right: '1vw',
                           width: '85vw',
                         }}
                       />
                      </div>
                    </>
                }
              </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Def Jam was the first label site Halo placed on the<span className="case-study-grid__header-sm--colored">&nbsp;Grand Royal&nbsp;</span>Wordpress platform.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">Def Jam was looking for something simple that highlighted their new black/gray/white branding. The design aesthetic was meant to reflect a shift in management. They did not want a typical artist image grid list on the homepage so we got creative by listing an artist roster so that users can hover on a name to reveal the artist’s press images.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <RightAlignedCaseStudy
            className={`${!isMobile ? 'case-study-grid__container--umg-cs5' : ''} case-study-grid__container--umg-background`}
            leftContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) =>
                    <>
                      <div className='case-study-grid__container--desktop'>
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs4/blue-dress-card.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '-130vh',
                            left: '7vw',
                            width: '38vw',
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs4/carrie-underwood.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '-15vh',
                            left: '14vw',
                            width: '38vw',
                            ...getParallaxAnimationStyles({
                              farness: 20,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs4/june-10th.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '30vw',
                            left: '30vw',
                            top: '-11vh',
                            ...getParallaxAnimationStyles({
                              farness: 50,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                      </div>
                      <div className='case-study-grid__container--mobile'>
                        <animated.img 
                          src="/slide-assets/umg/mobile_cs/group_cs4.png" 
                          alt=""
                          className='case-study-grid__container--mobile-image'
                          style={{ opacity: scrollReadMoreMobile ? '0' : '1'  }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/carrie-right.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '-55vw',
                            height: '100vh',
                            opacity:' 0.3',
                          }}
                        />
                      </div>
                    </>
                }
              </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Carrie Underwood wanted to<span className="case-study-grid__header-sm--colored">&nbsp;captivate fans immediately&nbsp;</span>with a big, beautiful video. </>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">Carrie Underwood’s site design focuses on her personality in performance. Instead of a hero image, we featured a video to captivate fans immediately while her logo, top navigation tabs and social icons queue up softly in the background.</p>
                <br />
                <p className="case-study-grid__copy--additional">In addition to designing Carrie’s site, Halo has managed updates for it, reskinning for various music and book releases.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <RightAlignedCaseStudy
            className='case-study-grid__container--umg-background'
            leftContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) =>
                    <>
                      <div className='case-study-grid__container--desktop'>
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/hollywoods-bleeding.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '45vw',
                            top: '-6vh',
                            left: '10vw',
                            ...getParallaxAnimationStyles({
                              farness: 100,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/justin-bieber.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '36vw',
                            bottom: '13vh',
                            left: '-5vw',
                            ...getParallaxAnimationStyles({
                              farness: 80,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: -5,
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/carrie-underwood.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            bottom: '90vh',
                            left: '30vw',
                            ...getParallaxAnimationStyles({
                              farness: 60,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/nicki-minaj.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '30vw',
                            bottom: '5vh',
                            left: '12vw',
                            ...getParallaxAnimationStyles({
                              farness: 60,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/luis-fousi.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '15vw',
                            bottom: '25vh',
                            left: '40vw',
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                        <animated.img 
                          src="/slide-assets/umg/case-studies/cs5/karol.png" 
                          alt=""
                          style={{
                            position: 'absolute',
                            width: '15vw',
                            bottom: '12vh',
                            left: '45vw',
                            ...getParallaxAnimationStyles({
                              farness: 20,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                            }, totalFade)
                          }}
                        />
                      </div>
                      <div className='case-study-grid__container--mobile'>
                        <animated.img 
                          src="/slide-assets/umg/mobile_cs/group_cs5.png" 
                          alt=""
                          className='case-study-grid__container--mobile-image'
                          style={{ opacity: scrollReadMoreMobile ? '0' : '1'  }}
                        />
                      </div> 
                    </>
                }
              </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Our<span className="case-study-grid__header-sm--colored">&nbsp;critical thinking skills are unmatched</span>. We design and create highly experiential sites.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">Fans like to dig deeper. We match the specs of the latest release with exclusive content and extra functions for a seamless boost to engagement. </p>
                <br />
                <p className="case-study-grid__copy--additional">Fans like to have fun. We build a variety of amusement architecture, from old school 8-bit video games to new school meme generators.</p>
                <br />
                <p className="case-study-grid__copy--additional">Fans like to feel a sense of belonging. We create organic spaces for an artist’s community to take shape by defining avenues that activate fan voices as well as passive participation.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
        >
          <SideBySideCaseStudy
            leftContent={
              <div
                className='case-study-grid__container--side-case'
              >
                <Typeography type='h3' primaryFontColor='white'>NEXT CASE STUDY:</Typeography>
                <Typeography type={!isMobile ? 'h1' : 'h2'} primaryFontColor='white'>FIBA asked us to create a checkout system <Typeography type='span' variant='secondary' secondaryFontColor='#F22000'>responsive to the diverse support needs</Typeography> of their tournaments around the world.</Typeography>
                <AnimatedButton
                  text='View Case Study'
                  onClick={() => {
                    setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false});
                    onPageDown(frame);
                  }}
                />
              </div>
            }
            rightContent={ !isMobile ?
              <>
                <img
                  style={{
                    position: 'absolute',
                    width: '43vw',
                    right: '7vw',
                    bottom: '26vh',
                  }}
                  src="/slide-assets/fiba/fiba-group.png" 
                  alt="" 
                />
                <img
                  style={{
                    position: 'absolute',
                    width: '32rem',
                    height: '10%',
                    right: '22vw',
                    bottom: '30vh',
                    top: '0',
                    objectFit: 'cover',
                    objectPosition: 'bottom',
                    
                  }}
                  src="/slide-assets/fiba/fiba_waves_cropped.png" 
                  alt="" 
                />
                <img
                  style={{
                    position: 'absolute',
                    width: '30vw',
                    right: '-12vw',
                    bottom: '-10vh',
                    objectFit: 'cover',
                    objectPosition: 'left top',
                    
                  }}
                  src="/slide-assets/fiba/fiba_waves.png" 
                  alt="" 
                />
              </>
              : 
              <>
               <img
                  style={{
                    position: 'absolute',
                    height: '38vh',
                    left: '-9vw',
                    top: '0',
                  }}
                  src="/slide-assets/fiba/fiba-group.png" 
                  alt="" 
                />
                 <img
                  style={{
                    position: 'absolute',
                    width: '60vw',
                    right: '-30vw',
                    top: '33vh',
                    transform: 'rotate(270deg)'
                    
                  }}
                  src="/slide-assets/fiba/fiba_waves.png" 
                  alt="" 
                />
              </>
            }
            rootContent={
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  background: 'radial-gradient(rgba(20, 20, 160, 1), rgba(20, 20, 100, 1))',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#0E0E52',
                    width: '100vw',
                    height: '100vh',
                    clipPath: `polygon(${!isMobile ? '60vw 0, 100vw 0, 100vw 100vh, 40vw 100vh' : '0 1%, 100% 10%, 100% 100%, 0% 100%'})`,
                  }}
                >
                </div>
              </div>
            }
          />
        </ScrollTransitionChild>

      </ScrollTransitionController>
  );
};

export default UMGSlide;