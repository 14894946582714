import { useAnimationFrames } from './AnimationWrapper';
import { useSpring, animated } from 'react-spring';
import { useMemo } from 'react';

interface BackgroundProps 
{
  colors: string[];
  breakpoints: number[];
}

export const Background = (props: BackgroundProps) =>
{
  const {
    colors,
    breakpoints,
  } = props;

  if(colors.length !== breakpoints.length / 2)
  {
    throw Error('Colors & breakpoints must be equal length');
  }

  const {
    frame,
  } = useAnimationFrames();

  const smoothedColors = useMemo(() => {
    return breakpoints.map((val, i) => {
      return colors[Math.floor(i / 2)];
    });
  }, [breakpoints, colors]);

  const { color } = useSpring({
    color: frame.to({
      range: breakpoints,
      output: smoothedColors,
    }),
  })

  return (
    <>
      <animated.div 
        className='animation-wrapper'
        style={{
          zIndex: -100,
          backgroundColor: color,
          width: '100vw',
          height: '100%',
          top: '0px',
        }}
      />
    </>
  )
}