import { ReactChild } from 'react';
import shallow from 'zustand/shallow';
import { useColorStore } from '../colorStore';
import BreadcrumbGroup from './BreadcrumbGroup';
import SlideStepper from "./SlideStepper";
import {animated} from 'react-spring';

interface LeftAlignedCaseStudyProps extends React.HTMLProps<HTMLDivElement> {
  breadcrumbs?: ReactChild[];
  header?: ReactChild;
  copy?: ReactChild;
  leftBoosted?: ReactChild;
  rightBoosted?: ReactChild;
  leftThin?: ReactChild;
  rightThin?: ReactChild;
  theme?: 'umg' | 'fiba' | 'atmos' | 'halo',
  topContent?: ReactChild;
  aditionalColor?: string,
  customHeaderColor?:string,
  customCopyColor?: string,
  additionalCloseColor?: boolean,
  onClosedClicked?: () => void;
}

export const LeftAlignedCaseStudy = (props: LeftAlignedCaseStudyProps) => {
  const {
    breadcrumbs = [],
    header,
    copy,
    leftBoosted,
    rightBoosted,
    leftThin,
    rightThin,
    theme,
    topContent,
    customHeaderColor,
    customCopyColor,
    additionalCloseColor = false,
    onClosedClicked = () => ({}),
  } = props;

  const {
    secondaryColor
  } = useColorStore((store) => ({
    secondaryColor: store.secondaryColor,
  }), shallow);
  
  return (
    <div className={`case-study-grid`}>
      <animated.div 
        className="case-study-grid--clip-overlay"
        style={{
          backgroundColor: secondaryColor,
        }}
      >
        <div 
          {...props} 
          className={`case-study-grid__container case-study-grid__container--content-left case-study-grid__container--${theme} ${props.className}`}
        >
          {
            topContent
          }
          <div className={`case-study-grid__column case-study-grid__column--left`}>        
            <div className="case-study-grid__content">
              <h1 className="case-study-grid__header" style={{color: customHeaderColor}}>
                {header}
              </h1>
              <BreadcrumbGroup>
                {
                  breadcrumbs
                }
              </BreadcrumbGroup>
              <p className="case-study-grid__copy" style={{color: customCopyColor}}>
                {
                  copy
                }
              </p>
              <div className="case-study-grid__stats">
                <div className="case-study-grid__stats--left">
                  <animated.p className="case-study-grid__stats--boosted" style={{color: secondaryColor}}>{leftBoosted}</animated.p>
                  <p className="case-study-grid__stats--thin">{leftThin}</p>
                </div>
                <div className="case-study-grid__stats--right">
                  <animated.p className="case-study-grid__stats--boosted" style={{color: secondaryColor}}>{rightBoosted}</animated.p>
                  <p className="case-study-grid__stats--thin">{rightThin}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="case-study-grid__column case-study-grid__column--right">
          </div>
          <SlideStepper
            closeColorOverride={additionalCloseColor}
            onCloseClicked={onClosedClicked}
          />
        </div>

      </animated.div>
    </div>
  );
};

export default LeftAlignedCaseStudy;