import { useLayoutEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import { useStore } from '../../store';
import { useAnimationFrames } from '../AnimationWrapper';
import ScrollTransitionController, { ScrollTransitionChild } from '../ScrollTransitionController';
import SlideGrid from '../SlideGrid';


export const LandingSlide = () => {

  const {
    exitInterp,
  } = useAnimationFrames();

  const {
    scrollingDisabled,
    setCaseStudyOptions,
  } = useStore((store) => ({
    scrollingDisabled: store.scrollingDisabled,
    setCaseStudyOptions: store.setCaseStudyOptions,
  }));

  const [hasSetupOptions, setHasSetupOptions] = useState<boolean>(false);

  useLayoutEffect(() => {
    if(!hasSetupOptions) {
      setHasSetupOptions(true);
      setCaseStudyOptions({
        currentPageIndex: undefined,  
        totalCaseStudies: 3,
        getMinPage: () => 2,
      })
    }
  }, [
    hasSetupOptions,
    setCaseStudyOptions,
  ])

  const {
    rootStyles,
  } = {
    rootStyles: useSpring({
      opacity: exitInterp.to({
        range: [0, 1],
        output: [1, 0],
      }),
    }),
  };

  return (
    <>
      <ScrollTransitionController
        style={{
          ...rootStyles,
          height: '100%',
          opacity: !scrollingDisabled ? 1 : rootStyles.opacity || '1',
          zIndex: 5,
        }}
      >
        <ScrollTransitionChild 
          getSpringProps={({totalFade}) => {
            return {
              x: totalFade.to({
                range: [0, 1, 2],
                output: ['0%', '0%', '-100%'],
              }),
            }
          }}
        >
          <SlideGrid
            variant='landing'
            headerText='Problem solving with a mix of creative talent and subject matter expertise'
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild 
          getSpringProps={({totalFade}) => {
            return {
              x: totalFade.to({
                range: [0, 1, 2],
                output: ['100vw', '0vw', '-100vw'],
              }),
            }
          }}
        >
          <SlideGrid
            headerText='Case study page one'
            leftSide={(
              <button onClick={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: true})}>
                Go back to main page
              </button>
            )}
          />
        </ScrollTransitionChild>
        
        <ScrollTransitionChild 
          getSpringProps={({totalFade}) => {
            return {
              x: totalFade.to({
                range: [0, 0.5, 1, 1.8, 2],
                output: ['100vw', '0vw', '0vw', '0vw', '0vw'],
              }),
            }
          }}
        >
          <SlideGrid
            headerText='Case study page two'
          />
        </ScrollTransitionChild>

      </ScrollTransitionController>
    </>
  )
}