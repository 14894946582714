import { useAnimationFrames } from "../AnimationWrapper"
import SlideGrid from "../SlideGrid";
import { animated, useSpring, config } from 'react-spring';
import { useStore } from "../../store";
import shallow from "zustand/shallow";
import { useAnimation } from "../../hooks/animation.hooks";
import { useCallback, useLayoutEffect, useState } from "react";
import ScrollTransitionController, { ScrollTransitionChild, springAnimationValueContext } from "../ScrollTransitionController";
import LeftAlignedCaseStudy from "../LeftAlignedCaseStudy";
import AnimatedButton from "../AnimatedButton";
import RightAlignedCaseStudy from "../RightAlignedCaseStudy";
import { commonStyles } from "../../common/variables";
import SideBySideCaseStudy from "../SideBySideCaseSudy";
import { Typeography } from "../Typeography";
import { useCurrentScrollDepth } from "../AnimatedSection";

export const AtmosSlide = () => 
{
   // temporary solution to scroll to next case study
   const {
    setFrame,
    frame,
    pageBreaks,
    isMobile
  } = useCurrentScrollDepth();

  const scrollIn = useCallback((startingFrame: number, endingFrame: number, timeoutMs: number, steps = 5) => {
    if(steps < 1)
    {
      throw Error('Tried animating with an invalid # of steps!');
    }

    const timeoutMsStep = timeoutMs / steps;
    const frameStep = (endingFrame - startingFrame) / steps;
    for(let i = 0; i <= steps; i++)
    {
      setTimeout(() => setFrame(startingFrame + i * frameStep), timeoutMsStep * i);
    }
  }, [setFrame]);

  const onPageDown = useCallback((frame: number) => {
    let currentBreakpointIndex = 0;
    for(; currentBreakpointIndex < pageBreaks.length; currentBreakpointIndex++)
    {
      if(frame >= pageBreaks[currentBreakpointIndex].enterStart && frame <= pageBreaks[currentBreakpointIndex].exitEnd)
      {
        break;
      }
    }
    currentBreakpointIndex += 1;
    if(currentBreakpointIndex >= pageBreaks.length)
    {
      setFrame(pageBreaks[pageBreaks.length-1].exitEnd);
    }
    else
    {
      scrollIn(frame, pageBreaks[currentBreakpointIndex].enterEnd, 500);
    }
  }, [pageBreaks, scrollIn, setFrame]);

  // end of temp solution

  

  const {
    enterInterp,
    exitInterp,
    entryExitInterp,
  } = useAnimationFrames();


  const {
    setCaseStudyOptions,
    scrollReadMoreMobile,
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
    scrollReadMoreMobile: store.scrollReadMoreMobile,
  }), shallow);

  const [hasSetupOptions, setHasSetupOptions] = useState<boolean>(false);
  const{
    getParallaxAnimationStyles,
    defaultScrollTransition,
  } = useAnimation();


  // This is a hack to combine contexts between the old scroller and the new one
  useLayoutEffect(() => {
    if(!hasSetupOptions) {
      setHasSetupOptions(true);
      setCaseStudyOptions({
        currentPageIndex: undefined,  
        totalCaseStudies: 6,
        getMinPage: () => 2,
      })
    }
  }, [
    hasSetupOptions,
    setCaseStudyOptions,
  ])

  const headerStyles = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['100vh', '0vh', '-100vh'],
    })
  })
  const dropShadow = 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))';

  const {
    shoe1Scroll,
    shoe1Fade,
    shoe2Scroll,
    shoe2Fade,
    shoe3Scroll,
    shoe3Fade,
    shoe4Scroll,
    shoe4Fade,
    shoe5Scroll,
    shoe5Fade,
    shoe6Scroll,
    shoe6Fade,
  } = useSpring({
    shoe1Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-30px', '1100px'],
    }),
    shoe1Fade: enterInterp.to({
      range: [0.0, 0.0526],
      output: [0.5, 1],
    }),
    shoe2Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-242px', '888px']
    }),
    shoe2Fade: enterInterp.to({
      range: [0.204, 0.264],
      output: [0.5, 1],
    }),
    shoe3Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-454px', '676px'],
    }),
    shoe3Fade: enterInterp.to({
      range: [.391, .451],
      output: [0.5, 1],
    }),
    shoe4Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-665px', '465px'],
    }),
    shoe4Fade: enterInterp.to({
      range: [0.578, .638],
      output: [0.5, 1],
    }),
    shoe5Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-876px', '254px'],
    }),
    shoe5Fade: enterInterp.to({
      range: [0.765, .825],
      output: [0.5, 1],
    }),
    shoe6Scroll: enterInterp.to({
      range: [0, 1],
      output: ['-1087px', '43px'],
    }),
    shoe6Fade: enterInterp.to({
      range: [.952, 1],
      output: [.5, 1],
    }),
  });

  const deleteFilter = useSpring({
    filter: exitInterp.to((e) => `blur(${Math.floor(e * 2)}px) grayscale(${e*100}%) ${dropShadow}`),
    scale: exitInterp.to({
      range: [0, 1],
      output: [1, 0.5],
    })
  })

  const curveIn1 = useSpring({
    x: enterInterp.to({
      range: [0, 0.25, 0.5, 0.75, 1],
      output: ['0vw', '20vw', '30vw', '20vw', '0vw'],
    }),
    y: enterInterp.to({
      range: [0, 1],
      output: ['-40vh', '0vh'],
    }),
    scale: enterInterp,
    config: config.gentle,
  })

  const curveIn2 = useSpring({
    x: enterInterp.to({
      range: [0, 0.25, 0.5, 0.75, 1],
      output: ['0vw', '-20vw', '-30vw', '-20vw', '0vw'],
    }),
    y: enterInterp.to({
      range: [0, 1],
      output: ['40vh', '0vh'],
    }),
    scale: enterInterp,
    config: config.gentle,
  })

  const curveIn3 = useSpring({
    y: enterInterp.to({
      range: [0, 0.25, 0.5, 0.75, 1],
      output: ['0vh', '-20vh', '-30vh', '-20vh', '0vh'],
    }),
    x: enterInterp.to({
      range: [0, 1],
      output: ['-40vw', '0vw'],
    }),
    scale: enterInterp,
    config: config.gentle,
  })

  const curveIn4 = useSpring({
    y: enterInterp.to({
      range: [0, 0.25, 0.5, 0.75, 1],
      output: ['0vh', '20vh', '30vh', '20vh', '0vh'],
    }),
    x: enterInterp.to({
      range: [0, 1],
      output: ['40vw', '0vw'],
    }),
    scale: enterInterp,
    config: config.gentle,
  })

  return (
    <ScrollTransitionController
        style={{
          height: '100%',
          zIndex: 5,
        }}
      >
        <ScrollTransitionChild>
          <>
            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe1.png'
              alt='shoe1'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '87px',
                position: 'fixed',
                zIndex: 2,
                bottom: '23.5px',
                right: shoe1Scroll, // originally 1013px
                opacity: shoe1Fade,
              }}
            />

            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe2.png'
              alt='shoe2'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '116px',
                position: 'fixed',
                zIndex: 2,
                bottom: '21px',
                right: shoe2Scroll, // originally 801px
                opacity: shoe2Fade,
              }}
            />

            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe3.png'
              alt='shoe3'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '86px',
                position: 'fixed',
                zIndex: 2,
                bottom: '24px',
                right: shoe3Scroll, // originally 589px
                opacity: shoe3Fade,
              }}
            />

            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe4.png'
              alt='shoe4'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '87px',
                position: 'fixed',
                zIndex: 2,
                bottom: '23.5px',
                right: shoe4Scroll, // originally 378px
                opacity: shoe4Fade,
              }}
            />

            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe5.png'
              alt='shoe5'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '86px',
                position: 'fixed',
                zIndex: 2,
                bottom: '24px',
                right: shoe5Scroll, // originally 167px
                opacity: shoe5Fade,
              }}
            />

            <animated.img 
              src='/slide-assets/atmos/atmos_slide_shoe6.png'
              alt='shoe6'
              className='animated-desktop-element'
              style={{
                width: '175px',
                height: '87px',
                position: 'fixed',
                zIndex: 2,
                bottom: '23.5px',
                right: shoe6Scroll, // originally -44px
                opacity: shoe6Fade,
              }}
            />

            <SlideGrid
              headerText="Crafting High-Demand Sales Solutions with Innovation"
              headerStyle={headerStyles}
              breadcrumb={['Retail', 'Innovation', 'DEV']}
              logoUrl='/slide-assets/logos/atmos-logo.svg'
              variant='atmos'
              rightSide={
                <>
                  <div>
                    <animated.img 
                      className='img-1'
                      alt='atmos products'
                      style={{
                        ...curveIn1,
                        ...deleteFilter
                      }}
                      />
                  </div>
                  <div>
                    <animated.img 
                      className='img-2'
                      alt='atmos products'
                      style={{
                        ...curveIn3,
                        ...deleteFilter
                      }}
                      />
                  </div>
                  <div>
                    <animated.img 
                      className='img-3'
                      alt='atmos products'
                      style={{
                        ...curveIn4,
                        ...deleteFilter
                      }}
                      />
                  </div>
                  <div>
                    <animated.img 
                      className='img-4'
                      alt='atmos products'
                      style={{
                        ...curveIn2,
                        ...deleteFilter
                      }}
                      />
                  </div>
                  <div>
                    <animated.img
                      className='img-mobile'
                      alt="Atmos Group"
                      style={headerStyles}
                    />
                  </div>
                </>
              }
              leftSide={
                <AnimatedButton
                  onClick={() => setCaseStudyOptions({ scrollingDisabled: false, controlledPageIndex: 2, isOpenCaseStudy: true })}
                  text="View Case Studies"
                  variant="dark"
                />
              }
            />
          </>  
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <LeftAlignedCaseStudy 
           topContent={
              <springAnimationValueContext.Consumer>
                {
                  ({totalFade}) => !isMobile ?
                      <>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs1/atmos_image.png" 
                            alt="content-img-1"
                            style={{
                              width: '35vw',
                              left: '0',
                              height: '100vh',
                              position: 'absolute',
                              objectFit: 'cover',
                              objectPosition: 'right',
                              zIndex: -1,
                            }}
                          />
                      </>
                      :
                      <>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs1/atmos_image.png" 
                            alt="content-img-1"
                            style={{
                              width: '100vw',
                              position: 'absolute',
                              zIndex: -1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_ny.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '4%',
                              width: '100vw',
                              height: '4%',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                            }}
                          />
                      </>
                }
              </springAnimationValueContext.Consumer>
            }
            className="case-study-grid__container--atmos-cs1"
            header={
              <>
               <div className='case-study-grid__container--atmos-cs1__mobile'>
                  Atmos
               </div>
              </>
            }
            breadcrumbs={[
              <>Retail</>,
              <>Innovation</>,
              <>DEV</>,
            ]}
            theme='atmos'
            customHeaderColor="black"
            customCopyColor="black"
            copy={<>Twenty years ago, Atmos was a small storefront selling vintage sneakers in Tokyo. Today, they are a global streetwear boutique with multiple locations whose Nike Air Max collabs are grails as legendary as their highly competitive online drops. Halo assists atmos in launching 200 limited releases every year.</>}
            onClosedClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false })}
            additionalCloseColor={true}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--atmos-cs2"
            theme="atmos"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) =>
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_big.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '22%',
                              left: '-25%',
                              width: '35%',
                              opacity: '10%',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_ny.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '2%',
                              left: '-25%',
                              width: '50vw',
                              height: '6%',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_client_app_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '8%',
                              width: '30vw',
                              top: '12vh',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_client_app_mobile_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '33vw',
                              width: '12vw',
                              top: '37vh',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_ny.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '-4%',
                              left: '0%',
                              width: '100vw',
                              height: '6%',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              transform: 'rotate(180deg)',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs2/atmos_big.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '8vh',
                              left: '18vw',
                              width: '90vw',
                              opacity: '10%',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/mobile_cs/group_cs1.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '4vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div> 
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm--atmos">Our DigiLine product has scaled up to reliably processing over<span className="case-study-grid__header-sm--colored-atmos">&nbsp;one million entries&nbsp;</span>in a 24-hour window.</span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy--atmos">In the land of competitive sneaker drops, atmos is king. They came to us for a better way to manage raffles, but the business case was there to prove we should swing for the rafters and look to build a platform that would leverage all kinds of scarcity beyond raffles.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
            additionalCloseColor={true}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--atmos-cs2"
            theme="atmos"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.img 
                            src='/slide-assets/atmos/atmos_slide_shoe1.png'
                            alt='shoe1'
                            className='animated-desktop-element'
                            style={{
                              width: '12%',
                              position: commonStyles.position.absolute,
                              zIndex: 2,
                              bottom: '60px',
                              right: '110vw',
                              opacity: shoe1Fade,
                            }}
                          />
                          <animated.img 
                            src='/slide-assets/atmos/atmos_slide_shoe2.png'
                            alt='shoe2'
                            className='animated-desktop-element'
                            style={{
                              width: '12%',
                              position: commonStyles.position.absolute,
                              zIndex: 2,
                              bottom: '60px',
                              right: '95vw',
                              opacity: shoe2Fade,
                            }}
                          />
                          <animated.img 
                            src='/slide-assets/atmos/atmos_slide_shoe3.png'
                            alt='shoe3'
                            className='animated-desktop-element'
                            style={{
                              width: '12%',
                              position: commonStyles.position.absolute,
                              zIndex: 2,
                              bottom: '60px',
                              right: '80vw',
                              opacity: shoe3Fade,
                            }}
                          />
                          <animated.img 
                            src='/slide-assets/atmos/atmos_slide_shoe4.png'
                            alt='shoe4'
                            className='animated-desktop-element'
                            style={{
                              width: '12%',
                              position: commonStyles.position.absolute,
                              zIndex: 2,
                              bottom: '60px',
                              right: '65vw',
                              opacity: shoe4Fade,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_flowers.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '2%',
                              left: '0',
                              width: '70%',
                              height: '3%',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_digiline_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '8',
                              width: '30vw',
                              top: '12vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_digi_release_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '25vw',
                              width: '30vw',
                              top: '35vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs3/atmos_flowers.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '4%',
                              right: '0',
                              width: '5%',
                              height: '90%',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 1,
                            }}
                          />
                          <animated.img
                            style={{
                              position: commonStyles.position.absolute,
                              left: '-13vw',
                              top: '40vh',
                              width: '727px',
                              height: '91px',
                            }}
                            src="/slide-assets/atmos/atmos-shoe-group.svg" 
                            alt="" 
                          />
                          <animated.img 
                            src="/slide-assets/atmos/mobile_cs/group_cs2.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div> 
                        
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm--atmos">We discerned a<span className="case-study-grid__header-sm--colored-atmos">&nbsp;need for speed anchored by both client and user.&nbsp;</span></span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy--atmos">We delivered templates that could be easily customized for the content management system while also adding features that utilize user behaviors for better personalized marketing and forecasting that can activate viral potential.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
            additionalCloseColor={true}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--atmos-cs2"
            theme="atmos"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                       <div className='case-study-grid__container--desktop'>
                        <animated.img 
                            src="/slide-assets/atmos/case-studies/cs4/atmos_shoe_big_2_crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '-23vw',
                              width: '87vw',
                              opacity: '10%',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs4/atmos_digiline_2.jpeg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '25vw',
                              width: '30vw',
                              top: '12vh',
                              objectFit: 'cover',
                              objectPosition: 'top',
                              zIndex: 3,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs4/atmos_digiline_3_crop.jpg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              left: '8',
                              width: '30vw',
                              top: '35vh',
                              zIndex: 1,
                              filter: commonStyles.shadow.drop,
                              borderRadius: commonStyles.radius.corner,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/atmos/case-studies/cs4/atmos_shoe_big_2_crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '-13vh',
                              left: '-23vw',
                              width: '580px',
                              opacity: '30%',
                              zIndex: 1,
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/atmos/mobile_cs/group_cs3.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ zIndex: 1, top: '8vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div> 
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <><span className="case-study-grid__header-sm--atmos">The result was our DigiLine product, which atmos uses to launch around<span className="case-study-grid__header-sm--colored-atmos">&nbsp;200 limited releases annually.&nbsp;</span></span></>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional case-study-grid__copy--atmos">Our extensive and data-driven user testing proved we could handle over 200,000 entries in one hour with no crashes, and it has scaled up to reliably processing over one million entries in a 24-hour window.</p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
            additionalCloseColor={true}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{zIndex: 2}}
        >
          <SideBySideCaseStudy
            leftContent={
              <div
                className='case-study-grid__container--side-case'
                style={{ marginTop: isMobile ? '38vh' : '' }}
              >
                <Typeography type='h3' primaryFontColor='white'>NEXT CASE STUDY:</Typeography>
                <Typeography type={!isMobile ? 'h1' : 'h2'} primaryFontColor='white'>On a good day, we build something to solve a challenge presented by one business. On a great day, we <Typeography type='span' variant='secondary' secondaryFontColor='#ea3745'>replicate and scale up</Typeography> what we’ve built to solve a challenge of business itself. </Typeography>
                <AnimatedButton
                  text='View Case Study'
                  onClick={() => {
                    setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false});
                    onPageDown(frame);
                  }}
                />
              </div>
            }
            rightContent={
              <springAnimationValueContext.Consumer>
                 {
                    ({totalFade}) => !isMobile ?
                      <>
                         <img
                            style={{
                              position: 'absolute',
                              width: '8vw',
                              top: '45vh',
                              left: '10vw'
                            }}
                            src="/slide-assets/atmos/ellipse.svg" 
                            alt="" 
                        />
                         <img
                            style={{
                              position: 'absolute',
                              width: '13vw',
                              height: '11vh',
                              right: '34vw',
                              objectFit: 'cover',
                              objectPosition: 'bottom',
                            }}
                            src="/slide-assets/atmos/ellipse.svg" 
                            alt="" 
                        />
                         <img
                            style={{
                              position: 'absolute',
                              width: '18vw',
                              bottom: '-4vh',
                              right: '2vw'
                            }}
                            src="/slide-assets/atmos/ellipse.svg" 
                            alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '30vw',
                            right: '15vw',
                            top: '14vh',
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: 120,
                            }, totalFade),
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_crop.png" 
                          alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '30vw',
                            right: '0',
                            top: '30vh',
                            borderRadius: `
                             ${commonStyles.radius.corner}
                             0 0
                             ${commonStyles.radius.corner}`,
                            filter: commonStyles.shadow.drop,
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: 120,
                            }, totalFade),
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_scheme.jpeg" 
                          alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '10vw',
                            left: '50vw',
                            top: '23vh',
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: 120,
                            }, totalFade),
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_mobile_profile.jpeg" 
                          alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '10vw',
                            right: '10vw',
                            top: '40vh',
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: 120,
                            }, totalFade),
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_mobile_media.png" 
                          alt="" 
                        />
                      </>
                      :
                      <>
                       <img
                            style={{
                              position: 'absolute',
                              width: '50vw',
                              bottom: '15vh',
                              left: '-20vw'
                            }}
                            src="/slide-assets/atmos/ellipse.svg" 
                            alt="" 
                        />
                       <img
                            style={{
                              position: 'absolute',
                              width: '50vw',
                              top: '25vh',
                              right: '-20vw'
                            }}
                            src="/slide-assets/atmos/ellipse.svg" 
                            alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '24vw',
                            right: '10vw',
                            top: '13vh',
                            zIndex: 2,
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_mobile_media.png" 
                          alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '65vw',
                            right: '15vw',
                            top: '6vh',
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_crop.png" 
                          alt="" 
                        />
                        <animated.img
                          style={{
                            position: 'absolute',
                            width: '65vw',
                            left: '8vw',
                            top: '14vh',
                            borderRadius: commonStyles.radius.corner,
                            filter: commonStyles.shadow.drop,
                          }}
                          src="/slide-assets/atmos/case-studies/cs5/next_case_study_scheme.jpeg" 
                          alt="" 
                        />
                      </>
                  }

              </springAnimationValueContext.Consumer>  
            }
            rootContent={
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  backgroundColor: '#252525',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#1B1B1B',
                    width: '100vw',
                    height: '100vh',
                    clipPath: `polygon(${!isMobile ? '60vw 0, 100vw 0, 100vw 100vh, 40vw 100vh' : '0 1%, 100% 10%, 100% 100%, 0% 100%'})`,
                  }}
                >
                </div>
              </div>
            }
          />
        </ScrollTransitionChild> 
      </ScrollTransitionController>
  )
}

