import { Children, ReactChild } from 'react';
import shallow from 'zustand/shallow';
import { useColorStore } from '../colorStore';
import {animated} from 'react-spring';

interface props extends React.HTMLProps<HTMLDivElement>{
  children: ReactChild | ReactChild[];
}

export const BreadcrumbGroup = (props: props) => {

  const children = Children.toArray(props.children);
  const {
    secondaryColor
  } = useColorStore((store) => ({
    secondaryColor: store.secondaryColor,
  }), shallow);

  return (
    <div className="breadcrumb">
      {
        children.map((child, index) => (
          <animated.span className='breadcrumb__chip breadcrumb__chip--test' style={{
            backgroundColor: secondaryColor,
          }}>
            <span className="breadcrumb__chip--inner">{child}</span>
          </animated.span>
        ))
      }
    </div>
  );
};

export default BreadcrumbGroup;