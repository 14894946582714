import { useMemo } from 'react';
import { animated } from 'react-spring';
import shallow from 'zustand/shallow';
import { useColorStore } from '../colorStore';

interface props {
  children?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  variant?:'primary' | 'secondary';
  primaryFontColor?:string,
  secondaryFontColor?: string,
}

export const Typeography = (props: props) => {

  const {
    secondaryColor,
  } = useColorStore((store) => ({
    secondaryColor: store.secondaryColor,
  }), shallow);

  const {
    children,
    style,
    type = 'p',
    variant = 'primary',
    primaryFontColor ='black',
    secondaryFontColor = secondaryColor
  } = props;

  const elementStyles = useMemo(() => ({
    ...style,
    color: variant === 'primary' ? primaryFontColor : secondaryFontColor,
  }), [
    style,
    variant,
    primaryFontColor,
    secondaryFontColor,
  ]);

  const element = useMemo(() => {
    switch(type){
      case 'h1':
        return <animated.h1 style={elementStyles} >{children}</animated.h1>;
      case 'h2':
        return <animated.h2 style={elementStyles} >{children}</animated.h2>;
      case 'h3':
        return <animated.h3 style={elementStyles} >{children}</animated.h3>;
      case 'h4':
        return <animated.h4 style={elementStyles} >{children}</animated.h4>;
      case 'h5':
        return <animated.h5 style={elementStyles} >{children}</animated.h5>;
      case 'h6':
        return <animated.h6 style={elementStyles} >{children}</animated.h6>;
      case 'p':
        return <animated.p style={elementStyles} >{children}</animated.p>;
      case 'span':
        return <animated.span style={elementStyles} >{children}</animated.span>;
      default:
        return <animated.p style={elementStyles} >{children}</animated.p>;
    }
  }, [
    type,
    children,
    elementStyles,
  ])

  return (
    element
  );
};

export default Typeography;