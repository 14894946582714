import { useCurrentScrollDepth } from './AnimatedSection';
import { createContext, Fragment, useContext } from 'react';
import { SpringValue } from 'react-spring';

export interface AnimationFramesProp {
  enterStart: number;
  enterEnd: number;
  exitStart: number;
  exitEnd: number;
}
interface AnimationWrapperProps 
{
  /**
   * Defines a trapezoidal animation graph, frame = scroll depth
   */
  animationFrames: AnimationFramesProp;

  children?: JSX.Element | JSX.Element[];
}

type AnimationFrames = {
  /**
   * 0-1, 1 if past animation
   */
  enterInterp: SpringValue<number>; 

  /**
   * 0-1, 1 if past animation (this is for exit so shouldn't happen)
   */
  exitInterp: SpringValue<number>;

  /**
   * 0-1, total interpolation from enterStart to exitEnd
   */
  interp: SpringValue<number>;

  /**
   * The raw frame value
   */
  frame: SpringValue<number>;

  /**
   * Value between 0-2, combination of entryInterp + exitInterp
   */
  entryExitInterp: SpringValue<number>;
};

const AnimationFramesContext = createContext<AnimationFrames>({
  enterInterp: new SpringValue(0),
  exitInterp: new SpringValue(0), 
  interp: new SpringValue(0), 
  frame: new SpringValue(0),
  entryExitInterp: new SpringValue(0),
});

/**
 * Provides the current AnimationFramesContext to children of 
 * a AnimationWrapper
 * @see AnimationFramesContext
 * @see AnimationWrapper
 */
export const useAnimationFrames = () => {
  return useContext(AnimationFramesContext);
}

/**
 * AnimationWrapper is a wrapper for all components that need to be animated.
 * It'll take care of the interpolation logic for the enter frames and exit frames
 * and provide those values to children via a context object, useAnimationFrames
 */
export const AnimationWrapper = (props: AnimationWrapperProps) =>
{
  const {
    animationFrames,
    children,
  } = props;

  const {
    frame: currentScrollFrame,
  } = useCurrentScrollDepth();

  const enabled = (currentScrollFrame >= animationFrames.enterStart && currentScrollFrame <= animationFrames.exitEnd);

  if(!enabled)
  {
    return <></>;
  }

  let enterInterp = 1.; // Defaults to 1 if past enter
  if(currentScrollFrame >= animationFrames.enterStart && currentScrollFrame <= animationFrames.enterEnd && 
    animationFrames.enterStart !== animationFrames.enterEnd) //Handles division by 0 case
  {
    enterInterp = (currentScrollFrame - animationFrames.enterStart) / (animationFrames.enterEnd - animationFrames.enterStart);
  }

  let exitInterp = 0.; // Defaults to 0 if not yet in exit
  if(currentScrollFrame >= animationFrames.exitStart && currentScrollFrame <= animationFrames.exitEnd)
  {
    if(animationFrames.exitStart === animationFrames.exitEnd)
    {
      exitInterp = 1.;
    }
    else
    {
      exitInterp = (currentScrollFrame - animationFrames.exitStart) / (animationFrames.exitEnd - animationFrames.exitStart);
    }
  }

  const interp = animationFrames.enterStart === animationFrames.exitEnd ? 1. :
    (currentScrollFrame - animationFrames.enterStart) / (animationFrames.exitEnd - animationFrames.enterStart);
  const entryExitInterp = enterInterp + exitInterp;

  const interpolations: AnimationFrames = {
    enterInterp: new SpringValue(enterInterp),
    exitInterp: new SpringValue(exitInterp),
    interp: new SpringValue(interp),
    frame: new SpringValue(currentScrollFrame),
    entryExitInterp: new SpringValue(entryExitInterp),
  }

  return (
    <>
      <AnimationFramesContext.Provider value={interpolations}>
        {children}
      </AnimationFramesContext.Provider>
    </>
  );
}