import { animated, SpringValue } from 'react-spring';

interface Props {
  headerText: string;
  leftSide?: JSX.Element;
  backgroundStyle?: Record<string, string | SpringValue>;
  breadcrumb?: string[];
  rightSide?: JSX.Element;
  style?: React.CSSProperties;
  logoUrl?: string;
  variant?: 'landing' | 'mercer' | 'umg' | 'halo' | 'atmos' | 'fiba' | 'contact-slide';
  headerStyle?: Record<string, string | SpringValue>;
}

const SlideGrid = (props: Props) => {
  const {
    headerText,
    breadcrumb = [],
    rightSide,
    leftSide,
    style,
    logoUrl,
    variant,
    headerStyle,
    backgroundStyle,
  } = props;

  return (
  <animated.section className={`slide-grid ${variant ? `slide-grid--${variant}` : ''}`} style={style}>
    <animated.div className='slide-grid__container'>
      <animated.div className="slide-grid--overlay" style={backgroundStyle}></animated.div>
      {/* Header Content */}
      <animated.div className="slide-grid__column slide-grid__column--left">
        <animated.div className="slide-grid__content"  style={headerStyle}>
          
          {/* Header */}
          <animated.h1 className="slide-grid__header">
            {headerText}
          </animated.h1>

          {/* Header breadcrumb */}
          <div className="breadcrumb">
            {
              breadcrumb.map((crumb, index) => {
                return (
                  <span key={index} className='breadcrumb__chip'>
                    <span className="breadcrumb__chip--inner">{crumb}</span>
                  </span>
                )
              })
            }
          </div>

          {leftSide}
        </animated.div>

        {/* Logo */}
        {
          logoUrl &&
          <div className="slide-grid__logo-container">
            {<img className="slide-grid__logo" src={logoUrl} alt="Halopowered Logo"/>}
          </div>
        }
      </animated.div>

      {/* Right side content */}
      <div className="slide-grid__column slide-grid__column--right">
        {
          rightSide
        }
      </div>
    </animated.div>
  </animated.section>
 );
}

export default SlideGrid;