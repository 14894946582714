export const ContactText = () => {
  return (
    <div className="contact-text">
      <div className="contact-text__block">
        <p className="contact-text__sub-header">
          Address
        </p>
        <h4 className="contact-text__header">
          127 W.26th Street, Suite 1002 New York, NY 10001
        </h4>
      </div>
      <div className="contact-text__block">
        <p className="contact-text__sub-header">
        Phone
        </p>
        <h4 className="contact-text__header">
        1-212-989-4256
        </h4>
      </div>
      <div className="contact-text__block">
        <p className="contact-text__sub-header">
        Email
        </p>
        <h4 className="contact-text__header">
        info@halopowered.com
        </h4>
      </div>
    </div>
  );
};

export default ContactText;