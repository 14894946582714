import { Formik } from 'formik';
import * as Joi from 'joi';
import { useState } from 'react';
import AnimatedButton from './AnimatedButton';

const FormPropsSchema = Joi.object({
  email: Joi.string().email({tlds: false}).required(),
  name: Joi.string().min(2).required(),
  message: Joi.string().required(),
  phoneNumber: Joi.any(),
  address: Joi.any(),
  middleName: Joi.any(),
  checkIfHuman: Joi.any(),
})


export const ContactForm = () => {

  const [submitted, setSubmitted] = useState<boolean>(false);

  if(submitted) {
    return (
      <>
        <h3 className="contact__header--success">Thank you for your message!</h3>
        <span className="contact__sub-header--success">Someone from the team will be in touch with you as soon as possible. </span>
      </>
    )
  }

  return (
    <Formik 
      initialValues={{
        name: '',
        email: '',
        message: '',
        phoneNumber: null,
        address: null,
        middleName: null,
        checkIfHuman: null,
      }}
      onSubmit={(data, {resetForm}) => {
        fetch(
          process.env.REACT_APP_SENGRID_BRIDGE_URL || '',
          {
            method: 'PUT',
            headers: { 
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          })
          .then(() => {
            setSubmitted(true);
          })
          .catch((reason) => {
            console.log('failed to submit', reason);
          })
          .finally(() => {
            resetForm();
          })
      }}
      validateOnChange={true}
      validate={values => {
        const errors = FormPropsSchema.validate(values, {abortEarly: false})
        // convert Joi error to Formik error format in a new object
        const formikErrors: Record<any, any> = {}
        if(errors.error){
          errors.error.details.forEach(error => {
            formikErrors[error.path[0]] = error.message
          })
        }
        return formikErrors
      }}>
        {({
          values,
          errors,
          touched,
          dirty,
          handleSubmit,
          handleChange,
          isSubmitting,
        }) => (
          <div className='contact__container'>
            <form className='contact__form' onSubmit={handleSubmit}>
              <input 
                className='contact__input'
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                placeholder='Name'
              />
              {errors.name && touched.name && errors.name}

              <input 
                className='contact__input'
                type='email'
                name='email'
                value={values.email}
                onChange={handleChange}
                placeholder='Email Address'
              />
              {errors.email && touched.email && errors.email}

              <input
                className='contact__input'
                type='text'
                name='phoneNumber'
                value={values.phoneNumber || ''}
                onChange={handleChange}
                placeholder='Phone number'
                hidden
              />

              <input
                className='contact__input gone'
                type='checkbox'
                name='checkIfHuman'
                tabIndex={-1}
                value={values.checkIfHuman || ''}
                onChange={handleChange}
                placeholder='Check if human'
              />

              <input
                className='contact__input gone'
                type='text'
                name='middleName'
                tabIndex={-1}
                value={values.middleName || ''}
                onChange={handleChange}
                placeholder='Middle name'
              />

              <input
                className='contact__input gone'
                type='text'
                name='address'
                tabIndex={-1}
                value={values.address || ''}
                onChange={handleChange}
                placeholder='address'
              />
              
              <textarea 
                className='contact__text-area'
                name='message'
                value={values.message}
                onChange={handleChange}  
                placeholder='Message'
              />
              {errors.message && touched.message && errors.message}

              <AnimatedButton
                className='contact__submit'
                variant='dark'
                type='submit'
                disabled={isSubmitting || Object.keys(errors).filter(val => !!val).length > 0 || !dirty}
                text='Submit'
              />
            </form>
          </div>
        )}
    </Formik>
  );
};

export default ContactForm;

