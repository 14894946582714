import { useCallback, useEffect, useMemo, useState } from 'react';
import { SpringValue, useSpring } from 'react-spring';
import shallow from 'zustand/shallow';
import { useColorStore } from '../colorStore';
import { usePrevious } from '../hooks/usePrevious';
 import { useStore } from '../store';
import { useCurrentScrollDepth } from './AnimatedSection';
import { useAnimationFrames } from './AnimationWrapper';

interface props {
}

/**
 * This is gross but while we're using the old animation controller it's required :(
 */
export const ColorController = (props: props) => {

  const {
    frame,
  } = useAnimationFrames();
  
  const {
    smoothedPageBreaks: breakpoints,
  } = useCurrentScrollDepth();

  const {
    currentPageIndex,
    scrollingDisabled,
    totalCaseStudies,
  } = useStore((store) => ({
    currentPageIndex: new SpringValue(store.currentPageIndex || 1),
    scrollingDisabled: store.scrollingDisabled,
    totalCaseStudies: store.totalCaseStudies,
  }), shallow);

  const {
    setSecondaryColor,
  } = useColorStore((store) => ({
    setSecondaryColor: store.setSecondaryColor,
  }), shallow)

  const prevScrollingDisabled = usePrevious(scrollingDisabled);
  const previousFrame = usePrevious(frame);

  const pageSecondaryColors = useMemo(() => [
    '#EA3745', // landing page
    '#34B4E0', // umg
    '#F11D01', // fiba
    '#0133a1', // atmos
    '#EA3745', // halo
    '#EA3745', // contact
  ], []);


  /**
   * this a 2D array of the secondary colors for each case study.
   * The first index is the page index, the second index is the case study index. 
   * !!!THIS DOES NOT OVERRIDE THE FIRST CASE STUDY COLORS!!!
   */
  const caseStudySecondaryColorOverrides = useMemo(() => [
    [], // landing
    [null, null, null, null, null, null, '#f22000'], // UMG
    [null, '#34B4E0', '#121279', '#121279', '#0133A1'], // fiba
    [null, null, null, null, '#ea3745'], // atmos
    [], // halo
    [], // contact
  ], []);

  const smoothedColors = useMemo(() => {
    return breakpoints.map((val, i) => {
      return pageSecondaryColors[Math.floor(i / 2)];
    });
  }, [
    breakpoints, 
    pageSecondaryColors
  ]);

  const currentVerticalPage = useMemo(() => {
    for(let i = 0; i < breakpoints.length/2; i++){
      if(frame.get() > breakpoints[i*2] && frame.get() < breakpoints[i*2+1]){
        return i;
      }
    }
    return 0;
  } , [
    frame, 
    breakpoints
  ]);

  const secondaryCaseStudyColors = useCallback((colors: (string | null)[]) => {
    const returncolors = Array.from({ length: totalCaseStudies }, (_, i) => {
      return colors[i-1] || pageSecondaryColors[currentVerticalPage];
    });
    return returncolors;
  }, [
    totalCaseStudies,
    pageSecondaryColors,
    currentVerticalPage,
  ]);

  // const colorOver = secondaryCaseStudyColors(caseStudySecondaryColorOverrides[currentVerticalPage]);

  const {
    secondaryColorForPage,
    secondaryColorForCaseStudy,
  } = {
    secondaryColorForPage: useSpring({
      color: frame.to({
        range: breakpoints,
        output: smoothedColors,
      })
    }),
    secondaryColorForCaseStudy: useSpring({
      color: currentPageIndex.to({
        // range equals array of indexes from 1 to totalCaseStudies
        range: Array.from(Array(totalCaseStudies).keys()).map((i) => i + 1),
        output: secondaryCaseStudyColors(caseStudySecondaryColorOverrides[currentVerticalPage]),
      })
    }),
  }

  const [init, setInit] = useState<boolean>(false);
  useEffect(() => {
    if(!init){
      setInit(true);
      setSecondaryColor(new SpringValue(pageSecondaryColors[0]))
    }
  }, [
    setSecondaryColor,
    pageSecondaryColors,
    init,
  ])

  /**
   * Secondary page color effect
   * Uses secondaryPageColors and secondaryCaseStudyColors to determine the color to use for the current page / case study
   */
  useEffect(() => {
    if(prevScrollingDisabled === scrollingDisabled && frame.get() === previousFrame.get()) return;
    if(scrollingDisabled) {
      setSecondaryColor(secondaryColorForPage.color);
    }else{
      setSecondaryColor(secondaryColorForCaseStudy.color || secondaryColorForPage.color);
    }
  }, [
    prevScrollingDisabled,
    scrollingDisabled,
    secondaryColorForPage,
    secondaryColorForCaseStudy,
    frame,
    previousFrame,
    setSecondaryColor,
  ]);

  return null;
};

export default ColorController;