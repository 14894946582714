import { SpringValue } from 'react-spring';
import create from 'zustand';

interface ColorStore {
  secondaryColor: SpringValue<string> | undefined;
  setSecondaryColor: (color: SpringValue<string>) => void;
}

export const useColorStore = create<ColorStore>((set, get) => ({
  secondaryColor: undefined,

  setSecondaryColor: (color: SpringValue<string> | undefined) => {
    set(({
      secondaryColor: color,
    }));
  },

}));