import React, { createContext, ReactElement, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { SpringValue, animated, UseSpringProps, useSpring } from 'react-spring';
import { usePrevious } from '../hooks/usePrevious';
import { useStore } from '../store';


export interface ScrollTransitionChildProps extends React.HTMLProps<HTMLDivElement>{
  children: JSX.Element,
  springAnimationValues?: {
    fadeIn: SpringValue<number>;
    fadeOut: SpringValue<number>;
    totalFade: SpringValue<number>;
  },
  getSpringProps?: (vals: {
    fadeIn: SpringValue<number>,
    fadeOut: SpringValue<number>,
    totalFade: SpringValue<number>,
  }) => UseSpringProps,
  immediate?: boolean,
}

export const springAnimationValueContext = createContext<{
  fadeIn: SpringValue<number>;
  fadeOut: SpringValue<number>;
  totalFade: SpringValue<number>;
}>({
  fadeIn: new SpringValue(0),
  fadeOut: new SpringValue(0),
  totalFade: new SpringValue(0),
});

/**
 * ScrollTransitionChild is a component that can be used to wrap non-animated elements. 
 * This is not a required component, so other children components can be used.
 * It will animate the children based on the getSpringProps prop function and the springAnimationValues prop.
 * The getSpringProps function is passed the springAnimationValues prop and should return a UseSpringProps object.
 */
export const ScrollTransitionChild = (props: ScrollTransitionChildProps) => {

  const {
    springAnimationValues = {
      fadeIn: new SpringValue(0), 
      fadeOut: new SpringValue(0),
      totalFade: new SpringValue(0),
    },
    getSpringProps = () => ({}),
    children,
  } = props;

  const spanStyles = useSpring({
    ...getSpringProps(springAnimationValues),
  });


  return (
    <springAnimationValueContext.Provider value={springAnimationValues}>
      <animated.div style={{
        display: 'block',
        width: '100%',
        ...spanStyles, 
        ...props.style
      }}>
        {children}
      </animated.div>
    </springAnimationValueContext.Provider>
  )
}

interface props extends React.HTMLProps<HTMLDivElement>{
  children: ReactElement<ScrollTransitionChildProps> | ReactElement<ScrollTransitionChildProps>[];

  /** used to pass default props to the child */
  defaultChildProps?: Partial<ScrollTransitionChildProps>;

  /** Virtual page size */
  pageSize?: number;

  /** space in the middle of the page, currently unsupported */
  pageSpace?: number;

  /** multiplier for scroll velocity default is 0.005 */
  scrollVelocity?: number;

  /** Don't let user scroll below page number, use page index and -1 or undefined to disable */
  minPage?: number;
}

/**
 * ScrollTransitionController is a component that wraps around multiple children
 * It provides multiple spring animated values to the children. It also does all the math
 * needed to calculate relative fade values for each child when rendering them. It also
 * has some external controls for the parent and vastly simplifies the logic of the children.
 */
export const ScrollTransitionController = animated((props: props) => {

  const {
    children,
    defaultChildProps = {},
    pageSize = 10,
    pageSpace = 0,
  } = props;

  const {
    controlledPageIndex,
    scrollingDisabled,
    isOpenCaseStudy,
    setCaseStudyOptions,
  } = useStore((store) => ({
    controlledPageIndex: store.controlledPageIndex,
    scrollingDisabled: store.scrollingDisabled,
    isOpenCaseStudy: store.isOpenCaseStudy,
    setCaseStudyOptions: store.setCaseStudyOptions,
  }));

  if(pageSpace !== 0){
    throw new Error('pageSpace is not supported yet');
  }
  
  const childrenArray = useMemo(() => Array.isArray(children) ? children : [children], [children]);
  const [scrollValue, setScrollValue] = useState(0);
  const [animateToScrollValue, setAnimateToScrollValue] = useState(controlledPageIndex * (pageSize + pageSpace));
  const [scrollValueBeforeAnimate, setScrollValueBeforeAnimate] = useState(0);
  
  /** Spring value used for controlling scrollValue with toPage */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const animatedScrollValue = useMemo(() => new SpringValue({
    from: scrollValueBeforeAnimate,
    to: animateToScrollValue,
    onChange: (value: any) => {
      setScrollValue(value);
    }
  }), [
    scrollValueBeforeAnimate,
    animateToScrollValue,
  ]);

  /** Index of the current child page being displayed */
  const currentPage = useMemo(() => {
    return Math.floor(scrollValue / (pageSize + pageSpace + .5));
  }, [scrollValue, pageSize, pageSpace]);
  const previousCurrentPageValue = usePrevious(currentPage);

  const pageIndexChanged = useCallback((pageIndex: number) => {
    setCaseStudyOptions({
      currentPageIndex: pageIndex + 1,
    })
  }, [
    setCaseStudyOptions,
  ]);

  useEffect(() => {
    if(previousCurrentPageValue !== currentPage){
      pageIndexChanged(currentPage);
    }
  }, [
    currentPage,
    previousCurrentPageValue,
    pageIndexChanged,
  ])

  /**
   * Please god, don't touch the math below.
   * It's magic. I don't know why it works. I just know it works.
   * It needs to be refactored for pageSpace to be supported. 
   * It's also not very efficient. But that's for later me to fix.
   */
  const renderChild = useCallback((index: number) => {
    const child = childrenArray[index];
    if(!child) return <></>;

    const pageProgress = (scrollValue - (pageSize * index)) / pageSize;
    const fadeInValue = pageProgress < .5 ? (pageProgress * (pageSize * 2)) / pageSize : 1;
    const fadeOutValue = pageProgress > .5 ? ((pageProgress - .5) * (pageSize * 2)) / pageSize : 0;
    
    const fadeIn = new SpringValue(fadeInValue);
    const fadeOut = new SpringValue(fadeOutValue);
    const totalFade = new SpringValue(fadeInValue + fadeOutValue);

    return React.cloneElement(child, {
      ...defaultChildProps,
      ...child.props,
      style: {
        ...defaultChildProps?.style,
        ...child.props?.style,
        position: 'absolute',
        top: '55px',
      },
      springAnimationValues: {
        fadeIn,
        fadeOut,
        totalFade,
      },
      immediate: previousCurrentPageValue !== currentPage,
    });

  }, [
    childrenArray,
    defaultChildProps,
    scrollValue,
    currentPage,
    pageSize,
    previousCurrentPageValue
  ]);

  /** Trigger scroll animation if the toPage value is updated */
  useEffect(() => {
    const newTargetScrollValue = (controlledPageIndex * (pageSize + pageSpace)) - (controlledPageIndex === 0 ? 0 : pageSize / 2);
    if(controlledPageIndex !== undefined && newTargetScrollValue !== animateToScrollValue){
      setScrollValueBeforeAnimate(scrollValue);
      setAnimateToScrollValue(newTargetScrollValue);
    }
  }, [
    controlledPageIndex,
    pageSize,
    pageSpace,
    scrollValue,
    animateToScrollValue,
  ])

  // render the children with the useSpring props
  return (
    <animated.div
      style={props.style}
      // removed additonal scrolling logic because it needs to be refactored
      onWheel={(e) => {
        const debuggingScroll = false
        if(debuggingScroll) console.log('===SCROLL===')
        
        if(scrollingDisabled) return;
        if(debuggingScroll) console.log('scrolling not disabled');

        e.stopPropagation();
      }}
      onTouchMove={(e) => {
        if(isOpenCaseStudy) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      {
        childrenArray.map((_, index) => {
          return renderChild(index);
        })
      }
    </animated.div>
  )
});

export default ScrollTransitionController;