import { AnimationFramesProp, useAnimationFrames } from './AnimationWrapper';
import { useMemo, useCallback, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useCurrentScrollDepth } from './AnimatedSection';
import { useStore } from '../store';

interface ColorSet {
  page: number;
  color: string;
  socialColor: string;
  foregroundColor: string;
  backgroundColor: string;
}

interface NavigationProps
{
  breakpoints: number[];
  colorRanges: ColorSet[];
  landingSlideFrames: AnimationFramesProp;
  contactSlideFrames: AnimationFramesProp;
}

const Navigation = (props: NavigationProps) => {
  const {
    frame,
  } = useAnimationFrames();

  const {
    setFrame
  } = useCurrentScrollDepth();

  const {
    scrollingDisabled,
    isOpenCaseStudy,
    setCaseStudyOptions
  } = useStore((store) => ({
    scrollingDisabled: store.scrollingDisabled,
    isOpenCaseStudy: store.isOpenCaseStudy,
    setCaseStudyOptions: store.setCaseStudyOptions,
  }));

  const {
    colorRanges,
    breakpoints,
    contactSlideFrames,
    landingSlideFrames,
  } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactHovered, setContactHovered] = useState(false);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [
    mobileMenuOpen, 
    setMobileMenuOpen,
  ]);

  const colorSetTransitions = useMemo(() => {
    const values = breakpoints.map((val, i): ColorSet => {

      const colorSet = colorRanges.find(color => color.page === Math.floor(i / 2));

      return {
        page: 0,
        color: '#0e0e0e',
        socialColor: '#0e0e0e',
        foregroundColor: '#0e0e0e',
        backgroundColor: 'transparent',
        ...colorSet
      };
    });

    return values;
  }, [breakpoints, colorRanges]);

  const transitionLanding = useCallback(() => {
    setFrame(landingSlideFrames.exitEnd);
    setTimeout(() => setFrame(landingSlideFrames.exitStart), 250)
    setTimeout(() => setFrame(landingSlideFrames.enterStart), 500);
  }, [landingSlideFrames.enterStart, landingSlideFrames.exitEnd, landingSlideFrames.exitStart, setFrame]);

  const transitionContact = useCallback(() => {
    setFrame(contactSlideFrames.enterStart);
    setTimeout(() => setFrame(contactSlideFrames.enterEnd), 250);
  }, [contactSlideFrames.enterEnd, contactSlideFrames.enterStart, setFrame]);

  const {
    socialIconFontColor,
    fontColor,
    mobileMenuForegroundColor,
    mobileMenuBackgroundColor,
  } = useSpring({
    socialIconFontColor: frame.to({
      range: breakpoints,
      output: colorSetTransitions.map(color => color.socialColor),
    }),
    fontColor: frame.to({
      range: breakpoints,
      output: colorSetTransitions.map(color => color.color),
    }),
    mobileMenuForegroundColor: frame.to({
      range: breakpoints,
      output: colorSetTransitions.map(color => color.foregroundColor),
    }),
    mobileMenuBackgroundColor: frame.to({
      range: breakpoints,
      output: colorSetTransitions.map(color => color.backgroundColor),
    })
  });

  const contactSpring = useSpring({
    width: contactHovered? '70%' : '0%',
  });

  const mobileMenuSpring = useSpring({
    opacity: mobileMenuOpen? 1 : 0,
    config: config.gentle,
    display: mobileMenuOpen ? 'flex' : 'none',
    backgroundColor: mobileMenuBackgroundColor,
    delay: 150,
  });

  return (
    <animated.div className={`nav nav__brand`}
      style={{backgroundColor: mobileMenuBackgroundColor}}
      onTouchMove={(e) => {
        if(mobileMenuOpen || isOpenCaseStudy ) e.stopPropagation();
      }}
    >
      <div className="nav__logo-container" >
        <img 
          className="nav__logo" 
          src="/navigation-assets/halo-logo-red.svg" 
          alt="Halopowered Logo" 
          onClick={() => {
            setCaseStudyOptions({
              controlledPageIndex: 0,
              scrollingDisabled: true,
            })
            setTimeout(() => {
              transitionLanding()
            }, scrollingDisabled ? 0 : 1000);
          }}
        />
      </div>
      <div className="nav__action-container">
        <animated.div className='nav__items' style={{color: fontColor}} onClick={() => transitionContact()} onMouseEnter={() => setContactHovered(true)} onMouseLeave={() => setContactHovered(false)} >
          Contact
          <animated.span className='nav__items--underline' style={contactSpring}/>
        </animated.div>

        <animated.div className="nav__social-container">
          <a className="nav__social-link" href="https://www.linkedin.com/company/halo-media/" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-linkedin-in nav__social-icon" style={{color: fontColor}}></animated.i></a>

          <a className="nav__social-link" href="https://mobile.twitter.com/halomedianyc" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-twitter nav__social-icon" style={{color: fontColor}}></animated.i></a>

          <a className="nav__social-link" href="https://www.instagram.com/halomediallc/?hl=en" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-instagram nav__social-icon" style={{color: fontColor}}></animated.i></a>
        </animated.div>
      </div>
      <div className="nav__menu-container">
        <animated.i
          style={{color: fontColor}}
          className={`fa-solid ${mobileMenuOpen? 'fa-times' : 'fa-bars'} nav__menu-icon`}
          onClick={() => toggleMobileMenu()}
        />
      </div>

      <animated.div className="nav__menu" style={mobileMenuSpring}>
        <animated.div className="container" style={{backgroundColor: mobileMenuForegroundColor}}>
          <div>
            <animated.h1 
              style={{color: fontColor}} 
              onClick={() => {
                transitionContact()
                setMobileMenuOpen(false);
              }}
            >
              Contact
            </animated.h1>
          </div>
          <animated.div className="nav__social--link_container">
            <a className="nav__social--link" href="https://www.linkedin.com/company/halo-media/" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-linkedin-in nav__social-icon" style={{color: socialIconFontColor}}></animated.i></a>

            <a className="nav__social--link" href="https://mobile.twitter.com/halomedianyc" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-twitter nav__social-icon" style={{color: socialIconFontColor}}></animated.i></a>

            <a className="nav__social--link" href="https://www.instagram.com/halomediallc/?hl=en" target="_blank" rel="noreferrer"><animated.i className="fa-brands fa-instagram nav__social-icon" style={{color: socialIconFontColor}}></animated.i></a>
          </animated.div>
        </animated.div>
      </animated.div>

    </animated.div>
 );
}

export default Navigation;