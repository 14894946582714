import create from 'zustand';

interface Store {
  verticalScrollControlsVisible: boolean;
  horizontalScrollControlsVisible: boolean;

  backwardScrollDisabled: boolean;
  forwardScrollDisabled: boolean;

  scrollingDisabled: boolean;
  currentPageIndex: number;
  controlledPageIndex: number;
  totalCaseStudies: number;
  scrollReadMoreMobile: boolean;
  closeColorOverride: boolean;
  isOpenCaseStudy: boolean;
  
  scrollRight: () => void;
  scrollLeft: () => void;

  getMinPage: () => number;

  _checkButtonsDisabled: () => void;
  _checkControlsVisible: () => void;
  
  /** 
   * Method to control case study options and settings
   */
  setCaseStudyOptions: (options?: {
    currentPageIndex?: number,
    controlledPageIndex?: number,
    totalCaseStudies?: number,
    scrollingDisabled?: boolean,
    scrollReadMoreMobile?: boolean,
    closeColorOverride?: boolean,
    isOpenCaseStudy?: boolean,
    getMinPage?: () => number,
  }) => void;

}

export const useStore = create<Store>((set, get) => ({
  verticalScrollControlsVisible: true,
  horizontalScrollControlsVisible: false,

  backwardScrollDisabled: false,
  forwardScrollDisabled: false,

  scrollingDisabled: true,
  currentPageIndex: 0,
  controlledPageIndex: 0,
  totalCaseStudies: 0,
  scrollReadMoreMobile: false,
  closeColorOverride: false,
  isOpenCaseStudy: false,

  getMinPage: () => (0),

  scrollRight: () => {
    const { 
      controlledPageIndex, 
      totalCaseStudies 
    } = get();
    if ((controlledPageIndex || 1) <= totalCaseStudies - 1) {
      set({
        controlledPageIndex: (controlledPageIndex || 1) + 1,
      });
    }
  },
  scrollLeft: () => {
    const { currentPageIndex } = get();
    if (currentPageIndex > 1) { 
      set({
        controlledPageIndex: currentPageIndex - 1,
      });
    }
  },

  _checkButtonsDisabled: () => {
    const { 
      currentPageIndex, 
      totalCaseStudies 
    } = get();
    set({
      backwardScrollDisabled: !get().scrollingDisabled ? (currentPageIndex <= 2) : undefined,
      forwardScrollDisabled: !get().scrollingDisabled ? !(currentPageIndex < totalCaseStudies) : undefined,
    });
  },

  _checkControlsVisible: () => {
    const scrollingDisabled = get().scrollingDisabled;
    set({
      verticalScrollControlsVisible: scrollingDisabled,
      horizontalScrollControlsVisible: !scrollingDisabled,
    });
  },

  setCaseStudyOptions: (options?: {
    currentPageIndex?: number,
    controlledPageIndex?: number,
    totalCaseStudies?: number,
    scrollingDisabled?: boolean,
    scrollReadMoreMobile?: boolean,
    closeColorOverride?: boolean,
    isOpenCaseStudy?: boolean,
    getMinPage?: () => number,
  }) => {

    set(state => ({
      ...state,
      ...options,
      getMinPage: options?.getMinPage || (() => (0)),
    }))
    get()._checkButtonsDisabled();
    get()._checkControlsVisible();
  },

}));