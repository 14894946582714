import { ReactChild } from 'react';
import { useStore } from '../store';
import SlideStepper from './SlideStepper';

interface props extends React.HTMLProps<HTMLDivElement>{
  rootContent?: ReactChild;
  leftContent?: ReactChild;
  rightContent?: ReactChild;
  additionalCloseColor?: boolean;
}

export const SideBySideCaseStudy = (props: props) => {

  const {
    rootContent,
    leftContent,
    rightContent,
    additionalCloseColor = false,
  } = props;

  const {
    setCaseStudyOptions,
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
  }));

  return (
    <div 
      {...props}
      style={{
        display: 'flex', 
        ...props.style
      }}
    >
      {rootContent}
      <div
       className='case-study-grid__container--side'
      >
        {
          leftContent
        }
      </div>
      <div
        className='case-study-grid__container--side'
      >
        {
          rightContent
        }
      </div>
      <SlideStepper 
       closeColorOverride={ additionalCloseColor }
       onCloseClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0})} />
    </div>
  );
};

export default SideBySideCaseStudy;