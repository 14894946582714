import { useMemo } from 'react';
import Navigation from './components/Navigation';
import { AnimatedSection } from './components/AnimatedSection';
import { AnimationWrapper } from './components/AnimationWrapper';
import { LandingSlide } from './components/slides/LandingSlide';
import { Background } from './components/Background';
import UMGSlide from './components/slides/UMGSlide';
import FibaSlide from './components/slides/FibaSlide';
import { AtmosSlide } from './components/slides/AtmosSlide';
import { HaloSlide } from './components/slides/HaloSlide';
import ContactUsSlide from './components/slides/ContactUsSlide';
import { useAnimation } from './hooks/animation.hooks';
import { PageNavigation } from './components/PageNavigation';
import { useSpring, animated } from 'react-spring';
import { useStore } from './store';
import ColorController from './components/ColorController';

function App() {
  const scrollInterval = 4;

  const {
    getAnimationFrames,
  } = useAnimation();

  const {
    scrollingDisabled,
    verticalScrollControlsVisible,
    horizontalScrollControlsVisible,
    currentPageIndex
  } = useStore((store) => ({
    currentPageIndex: store.currentPageIndex,
    scrollingDisabled: store.scrollingDisabled,
    verticalScrollControlsVisible: store.verticalScrollControlsVisible,
    horizontalScrollControlsVisible: store.horizontalScrollControlsVisible,
  }));

  const page1Frames = getAnimationFrames(0, undefined, 1, 1, 2 * scrollInterval);
  const page2Frames = getAnimationFrames(1, page1Frames, 3 * scrollInterval, 5 * scrollInterval, 2 * scrollInterval);
  const page4Frames = getAnimationFrames(1, page2Frames, 3 * scrollInterval, 5 * scrollInterval, 2 * scrollInterval);
  const page5Frames = getAnimationFrames(1, page4Frames, 3 * scrollInterval, 5 * scrollInterval, 2 * scrollInterval);
  const page6Frames = getAnimationFrames(1, page5Frames, 3 * scrollInterval, 5 * scrollInterval, 2 * scrollInterval);
  const page7Frames = getAnimationFrames(1, page6Frames, 3 * scrollInterval, 5 * scrollInterval, 2 * scrollInterval);
  const pageFrames = useMemo(() => (
    [
      page1Frames,
      page2Frames,
      page4Frames,
      page5Frames,
      page6Frames,
      page7Frames
    ]
  ), [
    page1Frames,
    page2Frames,
    page4Frames,
    page5Frames,
    page6Frames,
    page7Frames,
  ]);

  const smoothedBreakpoints = useMemo(() =>
  {
    const breakpoints: number[] = [];

    pageFrames.forEach(page => {
      breakpoints.push(page.enterStart);
      breakpoints.push(page.exitEnd);
    });

    return breakpoints;
  },[pageFrames]);

  const {
    verticalNavigationStyles,
    horizontalScrollControlStyles,
  } = {
    verticalNavigationStyles: useSpring({
      opacity: verticalScrollControlsVisible ? 0 : 1,
      to: {
        opacity: verticalScrollControlsVisible ? 1 : 0,
      }
    }),
    horizontalScrollControlStyles: useSpring({
      opacity: horizontalScrollControlsVisible ? 0 : 1,
      to: {
        opacity: horizontalScrollControlsVisible ? 1 : 0,
      }
    })
  }

  return (
    <AnimatedSection 
      startFrame={0} 
      finalFrame={pageFrames[pageFrames.length-1].exitEnd} 
      scrollFactor={scrollingDisabled ? 0.55 : 0}
      pageBreaks={pageFrames}
      smoothedPageBreaks={smoothedBreakpoints}
    >
      {/* Navigation */}
      <AnimationWrapper 
        animationFrames={{
          enterStart: 0,
          enterEnd: pageFrames[pageFrames.length-1].exitEnd,
          exitStart: pageFrames[pageFrames.length-1].exitEnd+1,
          exitEnd: pageFrames[pageFrames.length-1].exitEnd+1,
        }}
        >
        <Navigation 
          breakpoints={smoothedBreakpoints}
          landingSlideFrames={page1Frames}
          contactSlideFrames={page7Frames}
          colorRanges={[
            {
              page: 0,
              color: 'black',
              socialColor: '#FF0033',
              foregroundColor: '#DCDCDC',
              backgroundColor: 'white',
            },
            {
              // umg
              page: 1,
              color: 'white',
              socialColor: '#34B4E0',
              foregroundColor: 'rgba(134, 134, 134, 0.2)',
              backgroundColor: 'black',
            },
            {
              // fiba
              page: 2,
              color: 'white',
              socialColor: '#F11D01',
              foregroundColor: '#11117F',
              backgroundColor: '#0E0E52',
            },
            {
              // atmos
              page: 3,
              color: 'black',
              socialColor: '#0133A1',
              foregroundColor: '#EAEAEA',
              backgroundColor: 'white',
            },
            {
              // halo
              page: 4,
              color: currentPageIndex === 11 ? 'black' : 'white',
              socialColor: currentPageIndex === 11 ? 'black' : '#EA3745',
              foregroundColor: currentPageIndex === 11 ? '#DCDCDC' : '#252525',
              backgroundColor: currentPageIndex === 11 ? 'white' : '#1B1B1B',
            },
            {
              // contact us
              page: 5,
              color: 'black',
              socialColor: 'black',
              foregroundColor: '#DCDCDC',
              backgroundColor: 'white',
            }
          ]}
        />
      </AnimationWrapper>
      
      {/* Background */}
      <AnimationWrapper
        animationFrames={{
          enterStart: 0,
          enterEnd: pageFrames[pageFrames.length-1].exitEnd,
          exitStart: pageFrames[pageFrames.length-1].exitEnd+1,
          exitEnd: pageFrames[pageFrames.length-1].exitEnd+1,
        }}>
        <Background 
          colors={['white', 'black', '#0E0E52', 'white', currentPageIndex === 11 ? 'white' : '#1B1B1B', 'white']}
          breakpoints={smoothedBreakpoints}/>
      </AnimationWrapper>

      {/* Landing Slide 0-10 */}
      <AnimationWrapper animationFrames={page1Frames}>
        <LandingSlide />
      </AnimationWrapper>

      {/* Post-landing test slide 10-20 */}
      <AnimationWrapper animationFrames={page2Frames}>
        <UMGSlide />
      </AnimationWrapper>

      <AnimationWrapper animationFrames={page4Frames}>
        <FibaSlide />
      </AnimationWrapper>

      <AnimationWrapper animationFrames={page5Frames}>
        <AtmosSlide />
      </AnimationWrapper>

      <AnimationWrapper animationFrames={page6Frames}>
        <HaloSlide />
      </AnimationWrapper>

      <AnimationWrapper animationFrames={page7Frames}>
        <ContactUsSlide />
      </AnimationWrapper>

      <AnimationWrapper
        animationFrames={{
          enterStart: 0,
          enterEnd: pageFrames[pageFrames.length-1].exitEnd,
          exitStart: pageFrames[pageFrames.length-1].exitEnd+1,
          exitEnd: pageFrames[pageFrames.length-1].exitEnd+1,
        }}>
          <animated.span style={verticalNavigationStyles}>
            <PageNavigation />
          </animated.span>
      </AnimationWrapper>

      <AnimationWrapper
        animationFrames={{
          enterStart: 0,
          enterEnd: pageFrames[pageFrames.length-1].exitEnd,
          exitStart: pageFrames[pageFrames.length-1].exitEnd+1,
          exitEnd: pageFrames[pageFrames.length-1].exitEnd+1,
        }}>
          <animated.span style={horizontalScrollControlStyles}>
            <PageNavigation 
              horizontal
            />
          </animated.span>
        <ColorController />
      </AnimationWrapper>
    </AnimatedSection>
  );
}

export default App;
