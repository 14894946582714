import { ReactChild, useMemo, useState,useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useStore } from '../store';
import { useCurrentScrollDepth } from './AnimatedSection';
import SlideStepper from './SlideStepper';
import { onClickSignal } from './PageNavigation';
interface props extends React.HTMLProps<HTMLDivElement>{
  leftContent?: ReactChild;
  copyHeader?: ReactChild;
  copy?: ReactChild[] | ReactChild;
  additionalContent?: ReactChild | ReactChild[];
  additionalContentButtonTextHidden?: string;
  additionalContentButtonTextVisible?: string;
  additionalContentVisibleOverride?: boolean;
  additionalContentToggledWithButton?: boolean;
  additionalCloseColor?: boolean;
  additionalContentButtonClicked?: () => void;
  theme?: 'umg' | 'fiba' | 'atmos' | 'halo'
}

export const RightAlignedCaseStudy = (props: props) => {

  const {
    leftContent,
    copyHeader,
    copy,
    additionalContent,
    additionalContentButtonTextHidden,
    additionalContentButtonTextVisible,
    additionalContentVisibleOverride,
    additionalContentToggledWithButton = true,
    additionalCloseColor = false,
    additionalContentButtonClicked = () => ({}),
    theme,
  } = props;

  const {
    setCaseStudyOptions,
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
  }));

  const {
    isMobile,
  } = useCurrentScrollDepth();


  const [additionalContentVisible, setAdditionalContentVisible] = useState<boolean>(false);
  const additionalContentHeightSpring = useSpring({
    height: additionalContentVisible ? !isMobile ? '20vh' : '42vh' : '0vh',
    overflow: 'hidden',
  });
  
  useEffect(() => {
    const unsubscribe = onClickSignal.subscribe(() => {
      setAdditionalContentVisible(false);
      setCaseStudyOptions({ scrollReadMoreMobile: false});
    });

    return () => {
      unsubscribe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickSignal]);


  const additionalMobilePositionSpring = useSpring({
    top: additionalContentVisible ? '-9vh' : '0',
  });

  const showAdditionalContent = useMemo(() => 
    additionalContentVisibleOverride === true ||
    (additionalContentVisible && additionalContentToggledWithButton), 
  [
    additionalContentVisibleOverride,
    additionalContentVisible,
    additionalContentToggledWithButton,
  ]);

  return (
    <div 
      {...props}
      className={`case-study-grid__container case-study-grid__container--content-right case-study-grid__container--${theme} ${props.className}`}
    >
      <div className="case-study-grid__column case-study-grid__column--left" style={{zIndex: 1}}>
        <div className="case-study-grid__content">
          {
            leftContent
          }
        </div>
      </div>
      <div className="case-study-grid__column case-study-grid__column--right" style={{zIndex: 1}}>
        <animated.div className="case-study-grid__content"  style={additionalMobilePositionSpring}>
            <h1 className="case-study-grid__header-sm">
              {
                copyHeader
              }
            </h1>
            <p className="case-study-grid__copy">
              {
                copy
              }
            </p>
            <animated.div className="case-study-grid__content--additional-copy-wrap" style={additionalContentHeightSpring}>
              {additionalContent}
            </animated.div>
            <button 
              className="case-study-grid__button" 
              onClick={() => {
                additionalContentButtonClicked();
                if(additionalContentToggledWithButton) {
                  setAdditionalContentVisible(!additionalContentVisible);
                  setCaseStudyOptions({ scrollReadMoreMobile: !additionalContentVisible});
                }
              }}
            >
              {
                showAdditionalContent ?
                (
                  <>
                    <i className="fa-solid fa-minus case-study-grid__button-icon"/>
                    {additionalContentButtonTextVisible}
                  </>
                ) :
                (
                  <>
                    <i className="fa-solid fa-plus case-study-grid__button-icon"/>
                    {additionalContentButtonTextHidden}
                  </>
                )
              }
            </button>
        </animated.div>
      </div>
      <SlideStepper 
      closeColorOverride={additionalCloseColor}
      onCloseClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0})} />
    </div>
  );
};

export default RightAlignedCaseStudy;