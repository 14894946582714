import { animated, useSpring } from 'react-spring';
import AnimatedButton from '../AnimatedButton';
import { useAnimationFrames } from '../AnimationWrapper';
import ScrollTransitionController, { ScrollTransitionChild, springAnimationValueContext } from '../ScrollTransitionController';
import { useStore } from '../../store';
import SlideGrid from '../SlideGrid';
import LeftAlignedCaseStudy from '../LeftAlignedCaseStudy';
import RightAlignedCaseStudy from '../RightAlignedCaseStudy';
import { useAnimation } from '../../hooks/animation.hooks';
import { useCallback, useLayoutEffect, useState } from 'react';
import SideBySideCaseStudy from '../SideBySideCaseSudy';
import Typeography from '../Typeography';
import { useCurrentScrollDepth } from '../AnimatedSection';
import { commonStyles } from '../../common/variables';

interface props {
}

const FibaSlide = (props: props) => 
{

  // temporary solution to scroll to next case study
  const {
    setFrame,
    frame,
    pageBreaks,
  } = useCurrentScrollDepth();

  const scrollIn = useCallback((startingFrame: number, endingFrame: number, timeoutMs: number, steps = 5) => {
    if(steps < 1)
    {
      throw Error('Tried animating with an invalid # of steps!');
    }

    const timeoutMsStep = timeoutMs / steps;
    const frameStep = (endingFrame - startingFrame) / steps;
    for(let i = 0; i <= steps; i++)
    {
      setTimeout(() => setFrame(startingFrame + i * frameStep), timeoutMsStep * i);
    }
  }, [setFrame]);

  const onPageDown = useCallback((frame: number) => {
    let currentBreakpointIndex = 0;
    for(; currentBreakpointIndex < pageBreaks.length; currentBreakpointIndex++)
    {
      if(frame >= pageBreaks[currentBreakpointIndex].enterStart && frame <= pageBreaks[currentBreakpointIndex].exitEnd)
      {
        break;
      }
    }
    currentBreakpointIndex += 1;
    if(currentBreakpointIndex >= pageBreaks.length)
    {
      setFrame(pageBreaks[pageBreaks.length-1].exitEnd);
    }
    else
    {
      scrollIn(frame, pageBreaks[currentBreakpointIndex].enterEnd, 500);
    }
  }, [pageBreaks, scrollIn, setFrame]);

  // end of temp solution

  const {
    isMobile
  } = useCurrentScrollDepth();

  const {
    enterInterp,
    exitInterp,
    entryExitInterp,
  } = useAnimationFrames();

  const {
    defaultScrollTransition,
    getParallaxAnimationStyles,
  } = useAnimation();

  const [hasSetupOptions, setHasSetupOptions] = useState<boolean>(false);

  const {
    setCaseStudyOptions,
    scrollReadMoreMobile
  } = useStore((store) => ({
    setCaseStudyOptions: store.setCaseStudyOptions,
    scrollReadMoreMobile: store.scrollReadMoreMobile,
  }));

  const headerStyles = useSpring({
    y: entryExitInterp.to({
      range: [0, 1, 2],
      output: ['100vh', '0vh', '-100vh'],
    })
  })

  const {
    topWaveEnterTranslate,
    topWaveExitTranslate,
    bottomWaveEnterTranslate,
    bottomWaveExitTranslate,
  } = useSpring({
    topWaveEnterTranslate: enterInterp.to({
      range: [0, 1],
      output: ['-366px', '-285px'],
    }),
    topWaveExitTranslate: exitInterp.to({
      range: [0, 1],
      output: ['173px', '-622px'],
    }),
    bottomWaveEnterTranslate: enterInterp.to({
      range: [0, 1],
      output: ['100vw', '80vw'],
    }),
    bottomWaveExitTranslate: exitInterp.to({
      range: [0, 1],
      output: ['-200px', '-366px'],
    }),
  })

  const fadeInStyle = useSpring({
    scale: entryExitInterp.to({
      range: [0, 1, 2],
      output: [0, 1, 0.5],
    }),
    y: exitInterp.to({
      range: [0, 1],
      output: ['0vh', '-30vh'],
    }),
    opacity: entryExitInterp.to({
      range: [0, .5, 1.5, 2.],
      output: [0, 1, 1, 0]
    })
  })


  // This is a hack to combine contexts between the old scroller and the new one
  useLayoutEffect(() => {
    if(!hasSetupOptions) {
      setHasSetupOptions(true);
      setCaseStudyOptions({
        currentPageIndex: undefined,  
        totalCaseStudies: 6,
        getMinPage: () => 2,
      })
    }
  }, [
    hasSetupOptions,
    setCaseStudyOptions,
  ])

  return (
    <>
      <ScrollTransitionController
        style={{
          height: '100vh',
          zIndex: 5,
        }}
      >

        <ScrollTransitionChild>
          <>
            <div className='case-study-grid__container--desktop'>
              <animated.img 
                src='/slide-assets/fiba/fiba_waves.png'
                id="fibaSection"
                style={{
                  x: topWaveExitTranslate,
                  top: topWaveEnterTranslate,
                  position: 'fixed',
                  zIndex: 4,
                  width: '622px',
                  height: '366px',
                }}
              />
              <animated.img 
                src='/slide-assets/fiba/fiba_waves.png'
                style={{
                  x: bottomWaveEnterTranslate,
                  bottom: bottomWaveExitTranslate,
                  position: 'fixed',
                  zIndex: 2,
                  width: '622px',
                  height: '366px',
                  right: '-200px',
                }}
              />
            </div>
            <div className='case-study-grid__container--mobile'>
              <animated.img 
                src='/slide-assets/fiba/fiba_waves.png'
                id="fibaSection"
                style={{
                  position: 'fixed',
                  zIndex: 1,
                  width: '80vw',
                  top: '40vh',
                  left: '-50vw',
                  transform: 'rotate(270deg)' 
                }}
              />
              <animated.img 
                src='/slide-assets/fiba/fiba_waves.png'
                style={{
                  position: 'fixed',
                  zIndex: 2,
                  width: '80vw',
                  top: '90vh',
                  right: '-40vw',
                }}
              />
            </div>
           
            <SlideGrid
              headerText="Empowering the Biggest Brands with Cutting-Edge Ticketing Solutions"
              headerStyle={headerStyles}
              breadcrumb={['Ticketing', 'Design', 'DEV']}
              logoUrl='/slide-assets/logos/world-cup-logo.svg'
              variant='fiba'
              rightSide={
                <>
                  <div>
                    <animated.img 
                      className='img-1'
                      alt='basketball_landing'
                      style={fadeInStyle}
                    />
                  </div>

                  <div>
                    <animated.img 
                      className='img-2'
                      alt='fiba_seating'
                      style={fadeInStyle}
                    />
                  </div>

                  <div>
                    <animated.img 
                      className='img-3'
                      alt='fiba_events'
                      style={fadeInStyle}
                    />
                  </div>
                  <div>
                    <animated.img
                      className='img-mobile'
                      alt="Fiba Group"
                      style={headerStyles}
                    />
                  </div>
                </>
              }
              leftSide={
                <AnimatedButton
                  onClick={() => setCaseStudyOptions({ scrollingDisabled: false, controlledPageIndex: 2, isOpenCaseStudy: true})}
                  text="View Case Studies"
                />
              }
            />
          </>
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <LeftAlignedCaseStudy
            topContent={
                <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => !isMobile ?
                        <>
                          <animated.img 
                              src="/slide-assets/fiba/case-studies/cs1/fiba_img_2_crop.png" 
                              alt="content-img-1"
                              style={{
                                width: '35vw',
                                left: '45vw',
                                position: commonStyles.position.absolute,
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: 10,
                                  endingValue: 0,
                                }, totalFade),
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/fiba/case-studies/cs1/fiba_img_1.png" 
                              alt="content-img-1"
                              style={{
                                position: commonStyles.position.absolute,
                                height: '80vh',
                                bottom: '0',
                                left: '1vw',
                                ...getParallaxAnimationStyles({
                                  farness: 30,
                                  propertyName: 'x',
                                  propertyUnit: 'vw',
                                  startingValue: -12,
                                  endingValue: -7,
                                }, totalFade),
                              }}
                            />
                          </>
                          :
                          <>  
                           <animated.img 
                              src="/slide-assets/fiba/case-studies/cs1/fiba_img_1.png" 
                              alt="content-img-1"
                              style={{
                                position: commonStyles.position.absolute,
                                width: '100vw',
                                bottom: '0',
                                right: 'calc(100vw - 50%)',
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/fiba/case-studies/cs2/philippines.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                top: '15%',
                                right: '-20%',
                                width: 'calc(100vw - 65%)',
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/fiba/case-studies/cs2/japan.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                top: '40%',
                                right: '-20%',
                                width: 'calc(100vw - 65%)',
                              }}
                            />
                            <animated.img 
                              src="/slide-assets/fiba/case-studies/cs2/indonesia.png" 
                              alt=""
                              style={{
                                position: commonStyles.position.absolute,
                                top: '65%',
                                right: '-20%',
                                width: 'calc(100vw - 65%)',
                              }}
                            />
                        </>
                  }
                </springAnimationValueContext.Consumer>
            }
            theme='fiba'
            header="FIBA"
            aditionalColor='white'
            breadcrumbs={[
              <>Innovation</>,
              <>UI/UX</>,
              <>DEV</>,
            ]}
            copy={
              <>The International Basketball Federation (FIBA) is an association of over 200 national organizations that define the rules of basketball. They asked us to create a checkout system responsive to the diverse support needs of their tournaments around the world.</>
            }
            onClosedClicked={() => setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false})}
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--fiba-cs2"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                        <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/tp_3_crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '12vh',
                              left: '14vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/philippines.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '17%',
                              left: '-5%',
                              width: '10%',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/japan.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '43%',
                              left: '-5%',
                              width: '10%',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/indonesia.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '17%',
                              left: '-5%',
                              width: '10%',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/seafinder.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '30vh',
                              left: '27vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs2/fiba_checkout_crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '42vh',
                              left: '18vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: '-2',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/japan.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '17vh',
                              left: '52vw',
                              width: '35vw',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/fiba/japan.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '37vh',
                              left: '-25vw',
                              width: '125vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/mobile_cs/group_cs2.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '2vh', opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div> 
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>FIBA asked us to create an all-new checkout system to<span className="case-study-grid__header-sm--colored">&nbsp;support their tournaments around the world</span>.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">We built a storefront API to handle high-demand sales and enforce real-time inventory rules in a simple and intuitive booking platform with variable language, sponsors, venue and ticketing options.

                  <br/>The Philippines, Indonesia and Japan required varied cultural sensitivities and our team successfully adapted to the needs and inspirations of each nation. </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>
        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            className="case-study-grid__container--fiba-cs3 case-study-grid__container--fiba-cs3--background"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/scheme-1.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '15vh',
                              left: '8vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: 1,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/seafinder-mobile.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '15vh',
                              left: '45vw',
                              width: '12vw',
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/scheme-2.jpeg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '0',
                              left: '22vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: '-2',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/japan_01.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '7vh',
                              left: '0',
                              height: '30vh',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'y',
                                propertyUnit: 'vh',
                                startingValue: 40,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_manila.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '15vw',
                              width: '9vw',
                              height: '9vh',
                              objectFit: 'cover',
                              objectPosition: 'center bottom',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -3,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_okinawa.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '28vw',
                              width: '9vw',
                              height: '9vh',
                              objectFit: 'cover',
                              objectPosition: 'center bottom',
                              opacity: '0.3',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -3,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_jakarta.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '41vw',
                              width: '9vw',
                              height: '9vh',
                              objectFit: 'cover',
                              objectPosition: 'center bottom',
                              opacity: '0.3',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -3,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba-logo.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '6vh',
                              right: '-14vw',
                              opacity:'0.2',
                              width: '93vw',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_manila.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '8vh',
                              left: '-8vw',
                              width: '30vw',
                              opacity: '0.3',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_okinawa.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '8vh',
                              left: '35vw',
                              width: '30vw',
                              opacity: '0.3',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba_jakarta.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '8vh',
                              right: '-8vw',
                              width: '30vw',
                              opacity: '0.3',
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/mobile_cs/group_cs3.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '8vh',  opacity: scrollReadMoreMobile ? '0' : '1' }}
                          />
                        </div> 
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Our solution is being leveraged for the<span className="case-study-grid__header-sm--secondary-color">&nbsp;next Olympic Games</span>.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">In addition to the next Olympic Games, our solution is being leveraged for several yet-to-be announced Bowl Games, where TicketSocket will continue to bring best-in-class ticketing options to the market. </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{
            zIndex: 2,
          }}
        >
          <RightAlignedCaseStudy
            theme='fiba'
            className="case-study-grid__container--fiba-cs4 case-study-grid__container--fiba-cs4--background"
            leftContent={
              <springAnimationValueContext.Consumer>
                  {
                    ({totalFade}) => 
                      <>
                        <div className='case-study-grid__container--desktop'>
                          <animated.img 
                            src="/slide-assets/fiba/map.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0vh',
                              left: '3vw',
                              height: '65vh',
                              objectFit: 'cover',
                              objectPosition: 'center bottom',
                              filter: commonStyles.shadow.drop,
                              zIndex: 1,
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 20,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs3/fiba-logo.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '76vh',
                              left: '-24vw',
                              opacity:'0.2',
                              width: commonStyles.size.standard,
                              ...getParallaxAnimationStyles({
                                farness: 20,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -18,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/route_plane.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '0',
                              left: '12%',
                              height: '25vh',
                              ...getParallaxAnimationStyles({
                                farness: 80,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -5,
                                endingValue: -2,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/plane.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '14%',
                              left: '24%',
                              height: '8%',
                              ...getParallaxAnimationStyles({
                                farness: 100,
                                propertyName: 'y',
                                propertyUnit: 'vh',
                                startingValue: 4,
                                endingValue: -3,
                              }, totalFade),
                              ...getParallaxAnimationStyles({
                                farness: 100,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: -13,
                                endingValue: -10,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/fiba_mobile_1_crop.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              zIndex: '4',
                              top: '26vh',
                              left: '17vw',
                              width: '10vw',
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              ...getParallaxAnimationStyles({
                                farness: 50,
                                propertyName: 'x',
                                propertyUnit: 'vh',
                                startingValue: 15,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/team_pass_1.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '43vh',
                              left: '25vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: '2',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 15,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/team_pass_2.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              bottom: '0',
                              left: '30vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: '3',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vh',
                                startingValue: 15,
                              }, totalFade),
                            }}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/case-studies/cs4/team_pass.png" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '12vh',
                              left: '20vw',
                              width: commonStyles.size.standard,
                              borderRadius: commonStyles.radius.corner,
                              filter: commonStyles.shadow.drop,
                              zIndex: '1',
                              ...getParallaxAnimationStyles({
                                farness: 30,
                                propertyName: 'x',
                                propertyUnit: 'vw',
                                startingValue: 25,
                              }, totalFade),
                            }}
                          />
                        </div>
                        <div className='case-study-grid__container--mobile'>
                          <animated.img 
                            src="/slide-assets/fiba/map.svg" 
                            alt=""
                            style={{
                              position: commonStyles.position.absolute,
                              top: '10vh',
                              left: '-10vw',
                              height: '45vh',
                              filter: commonStyles.shadow.drop,
                              zIndex: 1,
                            }}
                          />
                           <animated.img 
                            src="/slide-assets/fiba/mobile_cs/group_cs4-1.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image'
                            style={{ top: '0',  left: 0, width: '60vw'}}
                          />
                          <animated.img 
                            src="/slide-assets/fiba/mobile_cs/group_cs4.png" 
                            alt=""
                            className='case-study-grid__container--mobile-image case-study-grid__container--halo-custom_horizontal'
                            style={{ top: '12vh', zIndex: 5, left: 'auto', right: 0, opacity: scrollReadMoreMobile ? '0' : '1'}}
                          />
                        </div> 
                      </>
                  }
                </springAnimationValueContext.Consumer>
            }
            copyHeader={
              <>Bringing FIBA World Cup host cities together<span className="case-study-grid__header-sm--secondary-color">&nbsp;before the tip-off</span>.</>
            }
            additionalContent={
              <>
                <p className="case-study-grid__copy--additional">FIBA wants ensure each of three host cities had equal representation and give local fans a deserved opportunity to attend games in the 2023 world cup. We built a unique pre-registration React application for the host cities Manila, Jakarta and Okinawa, complete with custom animations and interactions almost over night. </p>
              </>
            }
            additionalContentButtonTextHidden="read more"
            additionalContentButtonTextVisible="read less"
          />
        </ScrollTransitionChild>

        <ScrollTransitionChild
          getSpringProps={({totalFade}) => defaultScrollTransition(totalFade)}
          style={{zIndex: 2}}
        >
          <SideBySideCaseStudy
            leftContent={
              <div
                className='case-study-grid__container--side-case'
              >
                <Typeography type='h3' primaryFontColor='black'>NEXT CASE STUDY:</Typeography>
                <Typeography type={!isMobile ? 'h1' : 'h2'} primaryFontColor='black'>Halo assists atmos in launching around <Typeography type='span' variant='secondary' secondaryFontColor='#0133A1'>200 limited releases</Typeography> every year. </Typeography>
                <AnimatedButton
                  text='View Case Study'
                  variant='dark'
                  onClick={() => {
                    setCaseStudyOptions({ scrollingDisabled: true, controlledPageIndex: 0, isOpenCaseStudy: false});
                    onPageDown(frame);
                  }}
                />
              </div>
            }
            rightContent={
              <springAnimationValueContext.Consumer>
                 {
                    ({totalFade}) => !isMobile ?
                      <>
                         <img
                            style={{
                              position: commonStyles.position.absolute,
                              width: '43vw',
                              right: '7vw',
                              bottom: '26vh',
                            }}
                            src="/slide-assets/atmos/atmos-group-2.svg" 
                            alt="" 
                        />
                        <animated.img
                          style={{
                            position: commonStyles.position.absolute,
                            right: '0',
                            bottom: '55px',
                            ...getParallaxAnimationStyles({
                              farness: 30,
                              propertyName: 'x',
                              propertyUnit: 'vw',
                              startingValue: 120,
                            }, totalFade),
                          }}
                          src="/slide-assets/atmos/atmos-shoe-group.svg" 
                          alt="" 
                        />
                      </>
                      :
                      <>
                      <img
                            style={{
                              position: commonStyles.position.absolute,
                              width: '85vw',
                              left: '6vw',
                              top: '6vh',
                            }}
                            src="/slide-assets/atmos/atmos-group-2.svg" 
                            alt="" 
                      />
                       <animated.img
                          style={{
                            position: commonStyles.position.absolute,
                            left: '4vw',
                            top: '32vh',
                            width: '727px',
                            height: '91px',
                          }}
                          src="/slide-assets/atmos/atmos-shoe-group.svg" 
                          alt="" 
                        />
                      </>
                  }

              </springAnimationValueContext.Consumer>  
            }
            rootContent={
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  position: commonStyles.position.absolute,
                  top: '0',
                  left: '0',
                  backgroundColor: '#FFFFFF',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#EAEAEA',
                    width: '100vw',
                    height: '100vh',
                    clipPath: `polygon(${!isMobile ? '60vw 0, 100vw 0, 100vw 100vh, 40vw 100vh' : '0 1%, 100% 10%, 100% 100%, 0% 100%'})`,
                  }}
                >
                </div>
              </div>
            }
            additionalCloseColor={true}
          />
        </ScrollTransitionChild>


      </ScrollTransitionController>
    </>
  )
}

export default FibaSlide;