import { useColorStore } from '../colorStore';
import { useStore } from '../store';
import {animated} from 'react-spring';
import { useCurrentScrollDepth } from './AnimatedSection';

interface SlideStepperProps {
  closeColorOverride?: boolean;
  onCloseClicked?: () => void;
}

export const SlideStepper = (props: SlideStepperProps) => {

  const {
    secondaryColor,
  } = useColorStore((store) => ({
    secondaryColor: store.secondaryColor
  }));

  const {
    isMobile,
  } = useCurrentScrollDepth();

  const {
    closeColorOverride,
    onCloseClicked = () => ({}),
  } = props;

  const {
    totalCaseStudies,
    currentPageIndex,
    setCaseStudyOptions,
  } = useStore((store) => ({
    totalCaseStudies: store.totalCaseStudies,
    currentPageIndex: store.currentPageIndex,
    setCaseStudyOptions: store.setCaseStudyOptions
  }));

  return (
    <div className="slide-stepper">
      {
        (() => {
          if ( !isMobile) {
            const elements = [];
            for(let i = 0; i < totalCaseStudies - 1; i++) {
              const isCurrentPage = (i + 2) === currentPageIndex;
              elements.push(
                <animated.i 
                  key={`stepper-icon-${i}`} 
                  className={`fas fa-circle slide-stepper__icon ${isCurrentPage ? ' slide-stepper__icon--focused' : ''}`}
                  onClick={() => {
                    setCaseStudyOptions({
                      controlledPageIndex: i + 2,
                    });
                  }}
                  style={{
                    color: isCurrentPage ? secondaryColor : ' ',
                  }}
                />
              )
            }
            return elements;
          }
        })()
      }
      <animated.i 
        className={`fa-solid fa-xmark slide-stepper__close-icon ${currentPageIndex === 2 ? `slide-stepper__close-icon-first` : ''}`}
        style={{color: closeColorOverride ? '#1B1B1B' : ''}}

        onClick={onCloseClicked}></animated.i>
    </div>
  );
};

export default SlideStepper;