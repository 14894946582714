import { SpringValue, animated } from 'react-spring';
import { useCurrentScrollDepth } from './AnimatedSection';
import { useCallback, useState, useEffect } from 'react';
import { useStore } from '../store';
import { useColorStore } from '../colorStore';
import { signal } from '@preact/signals-react';

interface props {
  horizontal?: boolean;
}

interface ColorMap {
  [key: string]: [string, string] | undefined;
}

export const onClickSignal = signal(false);

export const PageNavigation = (props: props) => {
  const {
    setFrame,
    frame,
    pageBreaks,
  } = useCurrentScrollDepth();

  const {
    horizontal = false,
  } = props;

  const {
    scrollLeft,
    scrollRight,
    forwardScrollDisabled,
    backwardScrollDisabled,
  } = useStore((store) => ({
    scrollRight: store.scrollRight,
    scrollLeft: store.scrollLeft,
    forwardScrollDisabled: store.forwardScrollDisabled,
    backwardScrollDisabled: store.backwardScrollDisabled,
  }));

  const {
    secondaryColor,
  } = useColorStore((store) => ({
    secondaryColor: store.secondaryColor,
  }));

  const {
    isMobile,
  } = useCurrentScrollDepth();

  const disabledHexColor = '#C4C4C4';

  const scrollIn = useCallback((startingFrame: number, endingFrame: number, timeoutMs: number, steps = 5) => {
    if(steps < 1)
    {
      throw Error('Tried animating with an invalid # of steps!');
    }

    const timeoutMsStep = timeoutMs / steps;
    const frameStep = (endingFrame - startingFrame) / steps;
    for(let i = 0; i <= steps; i++)
    {
      setTimeout(() => setFrame(startingFrame + i * frameStep), timeoutMsStep * i);
    }
  }, [setFrame]);

  const onPageUp = useCallback((frame: number) => {
    let i = 0;
    for(; i < pageBreaks.length; i++)
    {
      if(frame >= pageBreaks[i].enterStart && frame <= pageBreaks[i].exitEnd)
      {
        break;
      }
    }
    i -= 1;
    if(i < 0)
    {
      i = 0;
    }

    scrollIn(frame, pageBreaks[i].enterEnd, 500);
  }, [pageBreaks, scrollIn]);

  const onPageDown = useCallback((frame: number) => {
    let currentBreakpointIndex = 0;
    for(; currentBreakpointIndex < pageBreaks.length; currentBreakpointIndex++)
    {
      if(frame >= pageBreaks[currentBreakpointIndex].enterStart && frame <= pageBreaks[currentBreakpointIndex].exitEnd)
      {
        break;
      }
    }
    currentBreakpointIndex += 1;
    if(currentBreakpointIndex >= pageBreaks.length)
    {
      setFrame(pageBreaks[pageBreaks.length-1].exitEnd);
    }
    else
    {
      scrollIn(frame, pageBreaks[currentBreakpointIndex].enterEnd, 500);
    }
  }, [pageBreaks, scrollIn, setFrame]);

  const [isLeftIconHovered, setIsLeftIconHovered] = useState(false);
  const [isRightIconHovered, setIsRightIconHovered] = useState(false);

  const handleIconMouseEnter = (isLeft: boolean) => {
    if (isLeft) {
      setIsLeftIconHovered(true);
    } else {
      setIsRightIconHovered(true);
    }
  };
  
  const handleIconMouseLeave = (isLeft: boolean) => {
    if (isLeft) {
      setIsLeftIconHovered(false);
    } else {
      setIsRightIconHovered(false);
    }
  };

  const getIconColors = (secondaryColor: SpringValue<string> | undefined, isHovered: boolean) => {
    const colorMap : ColorMap = {
      // secondaryColor: [arrowColor, circleColor]
      'rgba(234, 55, 69, 1)': ['white', 'rgb(234, 55, 69)'],
      'rgba(52, 180, 224, 1)': ['black', 'rgba(52, 180, 224, 1)'],
      'rgba(241, 29, 1, 1)': ['rgb(14, 14, 82)', 'rgba(241, 29, 1, 1)'],
      'rgba(242, 32, 0, 1)': ['rgb(14, 14, 82)', 'rgba(242, 32, 0, 1)'],
      'rgba(1, 51, 161, 1)': ['white', 'rgba(1, 51, 161, 1)'],
      'rgba(18, 18, 121, 1)': ['white', 'rgba(18, 18, 121, 1)'],
    };
  
    const colorKey = secondaryColor ? secondaryColor.get() : '';
    const [arrowColor, circleColor] = colorMap[colorKey] || ['', 'transparent'];
  
    return {
      arrowColor: isHovered ? arrowColor : '',
      circleColor: isHovered ? circleColor : 'transparent',
    };
  };

  const [leftIconColors, setLeftIconColors] = useState({ arrowColor: '', circleColor: 'transparent' });
  const [rightIconColors, setRightIconColors] = useState({ arrowColor: '', circleColor: 'transparent' });
  
  useEffect(() => {
    setLeftIconColors(getIconColors(secondaryColor, isLeftIconHovered));
    setRightIconColors(getIconColors(secondaryColor, isRightIconHovered));
  }, [secondaryColor, isLeftIconHovered, isRightIconHovered, leftIconColors, rightIconColors]);

  if(pageBreaks.length <= 1)
  {
    return <></>
  }

  const onFirstPage = frame >= pageBreaks[0].enterStart && frame <= pageBreaks[0].exitEnd;
  const onLastPage = frame >= pageBreaks[pageBreaks.length-1].enterStart;

  const backwardDisabled = backwardScrollDisabled !== undefined ?
    backwardScrollDisabled :
    onFirstPage;
  const forwardDisabled = forwardScrollDisabled !== undefined ?
    forwardScrollDisabled :
    onLastPage;

  return (
    <animated.div className={`page-navigation  ${horizontal? 'page-navigation__horizontal' : ''}`} style={{
    }}>
      {
        <animated.svg
          style={{
            fill: backwardDisabled ? disabledHexColor : secondaryColor,
            cursor: backwardDisabled ? 'default' : 'pointer',
            marginRight: horizontal  && !isMobile ? '6.5px' : '0',
            transform: horizontal && !isMobile ? 'rotate(270deg)' : 'none',
          }}
          className="page-navigation__icon page-navigation__icon--left"
          onClick={() => {
            if(!backwardDisabled && !horizontal){
              onPageUp(frame+10);
            }

            if(!backwardDisabled && horizontal){
              scrollLeft();
            }
            // Notify subscribers of the onClickSignal signal
            onClickSignal.value = !onClickSignal.value;
          }}
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          onMouseEnter={() => handleIconMouseEnter(true)}
          onMouseLeave={() => handleIconMouseLeave(true)}
        >
          <circle xmlns="http://www.w3.org/2000/svg" cx="13.9998" cy="14" r="13" 
            fill={backwardDisabled ? "transparent" : leftIconColors.circleColor}
          />
          <path d="M14.12 28.23C6.33 28.23 0 21.9 0 14.12C0 6.34 6.33 0 14.12 0C21.91 0 28.24 6.33 28.24 14.12C28.24 21.91 21.91 28.24 14.12 28.24V28.23ZM14.12 2.23C7.56 2.23 2.23 7.56 2.23 14.12C2.23 20.68 7.56 26 14.11 26C20.66 26 25.99 20.67 25.99 14.12C25.99 7.57 20.67 2.23 14.12 2.23Z"/>
          <path fill={backwardDisabled ? disabledHexColor : leftIconColors.arrowColor} d="M17.33 18.4301L17.2 18.3701L17.12 18.3201L17.03 18.1901L14.12 12.3601L11.17 18.2701L11.04 18.3601C10.97 18.4001 10.9 18.4201 10.82 18.4201H9.16005L9.04005 18.3701C8.98005 18.3401 8.92005 18.2901 8.88005 18.2401L8.83005 18.1701L8.80005 18.0401C8.80005 17.9601 8.80005 17.8801 8.84005 17.8201L13.76 7.96006L13.88 7.87006C13.95 7.83006 14.03 7.81006 14.11 7.81006H14.19L14.33 7.87006C14.41 7.91006 14.46 7.97006 14.5 8.05006L19.42 17.9201V18.0601C19.42 18.1401 19.39 18.2101 19.34 18.2701L19.29 18.3301L19.18 18.4001C19.12 18.4301 19.05 18.4501 18.98 18.4501H17.31L17.33 18.4301Z"/>
        </animated.svg>
      }
      {
        <animated.svg
          style={{
            fill: forwardDisabled ? disabledHexColor : secondaryColor,
            cursor: forwardDisabled ? 'default' : 'pointer',
            marginLeft: horizontal  && !isMobile ? '6.5px' : '0',
            transform: horizontal && !isMobile ? 'rotate(270deg)' : 'none',
          }}
          className={`page-navigation__icon page-navigation__icon--right`}
          onClick={() => {
            if(!forwardDisabled && !horizontal){
              onPageDown(frame);
            }
            if(!forwardDisabled && horizontal){
              scrollRight();
            }
              // Notify subscribers of the onClickSignal signal
            onClickSignal.value = !onClickSignal.value;
          }}
          onMouseEnter={() => handleIconMouseEnter(false)}
          onMouseLeave={() => handleIconMouseLeave(false)}
          key="right"
        >
          <circle xmlns="http://www.w3.org/2000/svg" cx="13.9998" cy="14" r="13" 
            fill={forwardDisabled ? "transparent" : rightIconColors.circleColor}
          />
          <path d="M14.12 0.24998C21.91 0.24998 28.24 6.57998 28.24 14.36C28.24 22.14 21.91 28.48 14.12 28.48C6.32999 28.48 -8.98336e-06 22.15 -8.30233e-06 14.36C-7.62131e-06 6.56998 6.32999 0.239979 14.12 0.239979L14.12 0.24998ZM14.12 26.25C20.68 26.25 26.01 20.92 26.01 14.36C26.01 7.79998 20.68 2.47998 14.13 2.47998C7.57999 2.47998 2.24999 7.80998 2.24999 14.36C2.24999 20.91 7.56999 26.25 14.12 26.25Z"/>
          <path fill={forwardDisabled ? disabledHexColor : rightIconColors.arrowColor} d="M10.9099 10.0499L11.0399 10.1099L11.1199 10.1599L11.2099 10.2899L14.1199 16.1199L17.0699 10.2099L17.1999 10.1199C17.2699 10.0799 17.3399 10.0599 17.4199 10.0599L19.0799 10.0599L19.1999 10.1099C19.2599 10.1399 19.3199 10.1899 19.3599 10.2399L19.4099 10.3099L19.4399 10.4399C19.4399 10.5199 19.4399 10.5999 19.3999 10.6599L14.4799 20.5199L14.3599 20.6099C14.2899 20.6499 14.2099 20.6699 14.1299 20.6699L14.0499 20.6699L13.9099 20.6099C13.8299 20.5699 13.7799 20.5099 13.7399 20.4299L8.81994 10.5599L8.81994 10.4199C8.81994 10.3399 8.84994 10.2699 8.89994 10.2099L8.94994 10.1499L9.05994 10.0799C9.11994 10.0499 9.18994 10.0299 9.25994 10.0299L10.9299 10.0299L10.9099 10.0499Z"/>
        </animated.svg>
      }
    </animated.div>
  )
}