import { useCallback } from 'react';
import { SpringValue } from 'react-spring';
import { useCurrentScrollDepth } from '../components/AnimatedSection';
import { AnimationFramesProp } from '../components/AnimationWrapper';

export const useAnimation = () => {

  const getAnimationFrames = useCallback((page: number, previous?: AnimationFramesProp, enterFrames?: number, spaceBeforeExit?: number, exitFrames?: number): AnimationFramesProp => {
    const enterStart = previous ? previous.exitEnd+1 : 0
    const enterEnd = enterStart + (enterFrames || 0);
    const exitStart = enterEnd + (spaceBeforeExit || 1);
    const exitEnd = exitStart + (exitFrames || 0);

    return {
      enterStart,
      enterEnd,
      exitStart,
      exitEnd,
    }
  }, []);

  const {
    isMobile,
  } = useCurrentScrollDepth();
  interface ParallaxAnimationOptions {
    /** A number from 0-100, 0 being the closest. This will give a flat animation schememe to use as a style. */
    farness: number;
    
    /** The property name to be animated. EX: x, y, opacity */
    propertyName: string;

    /** The unit to be used for animation */
    propertyUnit: string;
    
    /** The value to start the animation at */
    startingValue?: number;

    /** The value to end the animation at. Default 0 */
    endingValue?: number;
  }

  const getParallaxAnimationStyles = (options: ParallaxAnimationOptions, totalFade: SpringValue<number>) => {
    const {
      propertyName,
      propertyUnit,
      startingValue = 20,
      endingValue = 0,
    } = options;
    let { farness } = options;

    if(farness < 0) {
      farness = 0;
    }
    if(farness > 100) {
      farness = 100;
    }

    const decimalCloseness = farness / 100;
    const startingAnimationFrame = 1 - (decimalCloseness / 3);

    return {
      [propertyName]: totalFade.to({
        range: [0, startingAnimationFrame, 1, 2],
        output: [
          `${startingValue * decimalCloseness}${propertyUnit}`,
          `${startingValue * decimalCloseness}${propertyUnit}`, 
          `${endingValue}${propertyUnit}`, 
          `${endingValue}${propertyUnit}`
        ],
      }),
    }

  }

  const defaultScrollTransition = (fade: SpringValue<number>) => {
    let animation: any = [];
    if( !isMobile) {
       animation = {
        x: fade.to({
          range: [0, 1, 2],
          output: ['50vw', '0vw', '-50vw'],
        })
      }
    } else {
      animation = {
        y: fade.to({
          range: [0, 1, 2],
          output: ['50vh', '0vh', '-50vh'],
        })
      }
    }
    return animation;
  }

  return {
    getAnimationFrames,
    getParallaxAnimationStyles,
    defaultScrollTransition,
  };
}