import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { AnimationFramesProp } from './AnimationWrapper';

type ScrollDepth = {
  frame: number;
  setFrame: (frame: number) => void;
  isMobile: boolean;
  isPhoneLg: boolean;
  pageBreaks: AnimationFramesProp[];
  smoothedPageBreaks: number[];
}

interface Props {
  startFrame: number;
  finalFrame: number;
  scrollFactor?: number;
  keyScrollFactor?: number;
  touchScrollFactor?: number;
  children?: JSX.Element | JSX.Element[];
  pageBreaks: AnimationFramesProp[];
  smoothedPageBreaks: number[];
}

const CurrentScrollDepthContext = createContext<ScrollDepth>({
  frame: 0,
  setFrame: () => console.error('tried setting an uninitialized frame'),
  isMobile: false,
  isPhoneLg: false,
  pageBreaks: [],
  smoothedPageBreaks: [],
});

export const useCurrentScrollDepth = () => {
  return useContext(CurrentScrollDepthContext);
}

export const AnimatedSection = (props: Props) =>
{
  const { 
    startFrame,
    finalFrame,
    children,
    scrollFactor = 1,
    keyScrollFactor = 1,
    touchScrollFactor = 1,
    pageBreaks,
    smoothedPageBreaks,
  } = props;

  const touchEvents = useMemo(() => {
    return new Map<number, React.Touch>();
  }, []);

  // Get number of children and basically provide them all 0-100 for scroll depth and then switch to the next one
  const [scrollCount, setScrollCount] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isPhoneLg, setIsPhoneLg] = useState(window.innerWidth < 576);

  useEffect(() => {
    const windowResizeListener = () => {
      setIsMobile(window.innerWidth < 992);
      setIsPhoneLg(window.innerWidth < 576);
    }

    const arrowListener = (event: KeyboardEvent) => {
      if(event.key === 'ArrowDown')
      {
        const newScroll = scrollCount + keyScrollFactor;
        setScrollCount(newScroll > finalFrame ? finalFrame : newScroll);
      }
      else if(event.key === 'ArrowUp')
      {
        const newScroll = scrollCount - keyScrollFactor;
        setScrollCount(newScroll < startFrame ? startFrame : newScroll);
      }
    };

    document.addEventListener('keydown', arrowListener);
    window.addEventListener('resize', windowResizeListener);

    return () => {
      document.removeEventListener('keydown', arrowListener);
      window.removeEventListener('resize', windowResizeListener);
    }
  });

  return (
    <div
      className='h-100' 
      onWheel={(event) => {
        event.stopPropagation();
        // Add the scroll to the scroll count
        const delta = event.deltaY > 0 ? 1 : -1;

        // If the scroll count + delta is less than 0, set it to 0
        if (scrollCount + delta < startFrame) {
          setScrollCount(startFrame);
          return;
        }

        if(scrollCount + delta > finalFrame) {
          setScrollCount(finalFrame);
          return;
        }

        setScrollCount(scrollCount + delta * scrollFactor);
      }}
      onTouchMove={(event) => {
        const frameCoeff = window.innerHeight / 36;
        let frameDiff = 0;
        for(let i = 0; i < event.changedTouches.length; i++)
        {
          const touch = event.changedTouches[i];
          const lastTouch = touchEvents.get(touch.identifier);
          if(lastTouch)
          {
            const yDiff = (lastTouch.pageY - touch.pageY) / frameCoeff;
            frameDiff += yDiff;
          }
          touchEvents.set(touch.identifier, touch);
        }

        const newScroll = scrollCount + frameDiff * touchScrollFactor;

        if(newScroll < startFrame)
        {
          setScrollCount(startFrame);
        }
        else if (newScroll > finalFrame)
        {
          setScrollCount(finalFrame);
        }
        else
        {
          setScrollCount(newScroll);
        }
      }}
      onTouchStart={(event) => {
        for(let i = 0; i < event.changedTouches.length; i++)
        {
          touchEvents.set(event.changedTouches[i].identifier, event.changedTouches[i]);
        }
      }}
      onTouchEnd={(event) => {
        for(let i = 0; i < event.changedTouches.length; i++)
        {
          touchEvents.delete(event.changedTouches[i].identifier);
        }
      }}
    >
      <CurrentScrollDepthContext.Provider value={{ frame: scrollCount, setFrame: setScrollCount, isMobile, isPhoneLg, pageBreaks, smoothedPageBreaks }}>
        {children}
      </CurrentScrollDepthContext.Provider>
    </div>
  )
}
