import { SpringValue, animated, useSpring } from 'react-spring';
import { useAnimationFrames } from '../AnimationWrapper';
import SlideGrid from '../SlideGrid';
import ContactText from '../ContactText';
import ContactForm from '../ContactForm';
import { useCurrentScrollDepth } from '../AnimatedSection';

interface props {
  style?: Record<string, string | SpringValue>;
}

export const ContactUsSlide = (props: props) => {

  const {
    style,
  } = props;

  const {
    enterInterp,
  } = useAnimationFrames();

  const {
    isMobile,
  } = useCurrentScrollDepth();

  const headerStyles = useSpring({
    y: enterInterp.to({
      range: [0, .5, 1],
      output: ['100vh', '0vh', '0vh'],
    })
  })

  const formStyles = useSpring({
    opacity: enterInterp.to({
      range: [0, .5, 1],
      output: [0, 0, 1],
    }),
  });

  return (
    <animated.div style={style} className="h-100">
      <SlideGrid
        headerText="We're excited to hear about your project"
        headerStyle={headerStyles}
        breadcrumb={[]}
        leftSide={
          <ContactText />
        }
        variant='contact-slide'
        rightSide={
          <animated.div className='contact' style={isMobile ? formStyles : undefined}>
            <ContactForm/>
          </animated.div>
        }
      />
    </animated.div>
  );
};

export default ContactUsSlide;