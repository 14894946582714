interface styles {
    position: any,
    radius: any,
    shadow: any,
    size: any,
}

export const commonStyles: styles = {
    position: {
        absolute: 'absolute',
        relative: 'relative'
    },
    radius: {
        corner: '8px',
    },
    shadow: {
        drop: 'drop-shadow(0 4px 13px rgba(0, 0, 0, 0.49))'
    },
    size: {
        standard: '33vw'
    }
}